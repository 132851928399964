const en = {
  common: {
    save: 'Save',
    cancel: 'Cancel',
    confirm: 'Confirm',
    signIn: 'Sign In',
    submit: 'Submit',
    letsGo: "Let's Go",
    okGotIt: 'Ok, Got It',
    skip: 'Skip',
    yes: 'Yes',
    no: 'No',
    delete: 'Delete',
    notFoundPage: 'Page not found',
    totalCountErrors: ' Errors, See Above',
    waitingForApproval: 'Pending Approval',
    internalServerError: 'Internal Server Error',
    goToLogout: 'Log Out And Proceed Later',
    typeTextHere: 'Type text here...',
    saveAndReturnLater: 'Save and Return Later',
    noData: 'No data',
    noResultsFound: 'No Results Found',
  },

  validations: {
    required: 'Required field',
    email: 'Invalid email address',
    minPassword: 'Must be {{size}} characters or more',
    doNotMatch: 'Passwords do not match',
    phone: 'Phone number is not valid',
    phoneLength: 'Phone number must be 10 digits long',
    zipCode: 'Please enter a valid ZIP code in the format XXXXX or XXXXX-XXXX',
    ssn: 'Please enter a valid Social Security Number in the format XXX-XX-XXXX.',
  },

  pagination: {
    itemsPerPage: 'Items per page',
  },

  sidebarItems: {
    events: 'Events',
    users: 'Users',
    broadcast: 'Broadcast',
    userDirectory: 'User Directory',
    fleetManagement: 'Fleet Management',
    dashboard: 'Dashboard',
    reports: 'Reports',
    inventory: 'Inventory',
    myRosters: 'My Rosters',
    myApprovals: 'My Approvals',
    myManagedUsers: 'User Management',
    notifications: 'Notifications',
    settings: 'Settings',
    myAccount: 'My Account',
    tables: 'Tables',
    rbacManagement: 'RBAC Management',
    guide: 'Guides',
  },

  /* Entities */
  roles: {
    name: 'Name',
    slug: 'Slug',
    description: 'Description',
  },

  linemen: {
    fullName: 'Full Name',
    firstName: 'First Name',
    lastName: 'Last Name',
    location: 'Location',
    utilityClassification: 'Utility Class.',
    unionClassification: 'Union Class.',
    timesheetInvoiceClassification: 'Timesheet/Invoice Class.',
    classification: 'Classification',
    phone: 'Phone',
    email: 'Email',
    crew: 'Crew Id',
    fleet: 'Assign Fleet',
    company: 'Company Tag',
    gender: 'Gender',
    lodging: 'lodging',
  },

  users: {
    uuid: 'UUID',
    firstName: 'First Name',
    lastName: 'Last Name',
    registrationDate: 'Registration Date',
    preferredName: 'Preferred Name',
    localUnion: 'Local Union',
    email: 'Email',
    phone: 'Phone Number',
    roles: 'Role(s)',
    status: 'User Status',
    clearStatus: 'Clear Status',
    street: 'Street',
    city: 'City',
    state: 'State',
    zipCode: 'ZIP Code',
    action: 'Action',
    addToFavorite: 'Favorite',
    verificationStatus: 'Union & class info',
  },

  rosterStatuses: {
    DRAFT: 'Draft',
    PENDING_APPROVAL: 'Pending Approval',
    MOBILIZED: 'Mobilized',
    ACTIVATED: 'Activated',
    DEMOBILIZED: 'Demobilized',
    CLOSED: 'Closed',
  },

  vehicles: {
    type: 'Type',
    companyTag: 'Company Tag',
    yardFK: 'Yard',
    assignedTo: 'Assigned To',

    BUCKET_TRUCK: 'Bucket Truck',
    SQUIRT_BUCKET: 'Squirt Bucket',
    DIGGER_DERRICK: 'Digger / Derrick',
    MECHANIC_TRUCK: 'Mechanic Truck',
    BACKYARD_MACHINE: 'Backyard Machine w/ Trailer',
    TRAILER_POLE: 'Pole Trailer',
    TRAILER_SAFETY: 'Safety Trailer',
    TRAILER_MATERIAL: 'Material Trailer',
    TRAILER_CARGO: 'Cargo Trailer',
    TRAILER_LOWBOY: 'Lowboy Trailer',
    TRAILER_UTILITY: 'Utility Trailer',
    PICKUP_TRUCK: 'Pick-up Truck',
    VAN: 'Van',
    SUV: 'Sport Utiity Vehicle',
  },

  states: {
    AL: 'Alabama',
    AK: 'Alaska',
    AZ: 'Arizona',
    AR: 'Arkansas',
    CA: 'California',
    CO: 'Colorado',
    CT: 'Connecticut',
    DE: 'Delaware',
    DC: 'District of Columbia',
    FL: 'Florida',
    GA: 'Georgia',
    HI: 'Hawaii',
    ID: 'Idaho',
    IL: 'Illinois',
    IN: 'Indiana',
    IA: 'Iowa',
    KS: 'Kansas',
    KY: 'Kentucky',
    LA: 'Louisiana',
    ME: 'Maine',
    MD: 'Maryland',
    MA: 'Massachusetts',
    MI: 'Michigan',
    MN: 'Minnesota',
    MS: 'Mississippi',
    MO: 'Missouri',
    MT: 'Montana',
    NE: 'Nebraska',
    NV: 'Nevada',
    NH: 'New Hampshire',
    NJ: 'New Jersey',
    NM: 'New Mexico',
    NY: 'New York',
    NC: 'North Carolina',
    ND: 'North Dakota',
    OH: 'Ohio',
    OK: 'Oklahoma',
    OR: 'Oregon',
    PA: 'Pennsylvania',
    RI: 'Rhode Island',
    SC: 'South Carolina',
    SD: 'South Dakota',
    TN: 'Tennessee',
    TX: 'Texas',
    UT: 'Utah',
    VT: 'Vermont',
    VA: 'Virginia',
    WA: 'Washington',
    WV: 'West Virginia',
    WI: 'Wisconsin',
    WY: 'Wyoming',
  },

  provinces: {
    AB: 'Alberta',
    BC: 'British Columbia',
    MB: 'Manitoba',
    NB: 'New Brunswick',
    NL: 'Newfoundland and Labrador',
    NS: 'Nova Scotia',
    ON: 'Ontario',
    PE: 'Prince Edward Island',
    QC: 'Quebec',
    SK: 'Saskatchewan',
    NT: 'Northwest Territories',
    NU: 'Nunavut',
    YT: 'Yukon',
  },

  userRoles: {
    SYSTEM_ADMIN: 'System Admin',
    SYSTEM_MANAGER: 'System Manager',
    SUPER_ADMIN: 'Super Admin',
    STORM_ADMIN: 'Storm Admin',
    BUSINESS_MANAGER: 'Business Manager',
    GENERAL_FOREMAN: 'General Foreman',
    LINEMAN: 'Lineman',
    FLEET_MANAGER: 'Fleet Manager',
    YARD_HAND: 'Yard Hand',
    MECHANIC: 'Mechanic',
    SAFETY_MANAGER: 'Safety Manager',
  },

  cdlEndorsements: {
    N_A: 'N/A',
    T_DOUBLE_TRIPLE_TRAILERS: 'T - Double/Triple Trailers',
    P_PASSENGER: 'P - Passenger',
    N_TANK_VEHICLE: 'N - Tank Vehicle',
    H_HAZARDOUS_MATERIALS: 'H - Hazardous Materials',
    X_COMBINATION_TANK_HAZARDOUS: 'X - Combination Tank and Hazardous',
    S_SCHOOL_BUS: 'S - School Bus',
  },

  cdlRestrictions: {
    N_A: 'N/A',
    L_FULL_AIR_BRAKE_SYSTEM_PROHIBITED: 'L - Full Air Brake System Prohibited',
    Z_CMV_FULL_AIR_BRAKE_SYSTEM_PROHIBITED: 'Z - CMV with Full Air Brake System Prohibited',
    E_MANUAL_TRANSMISSION_PROHIBITED: 'E - Manual Transmission Prohibited',
    O_CLASS_A_VEHICLE_FIFTH_WHEEL_CONNECTION_PROHIBITED: 'O - Class A Vehicle with Fifth Wheel Connection Prohibited',
    M_CLASS_B_OR_C_PASSENGER_VEHICLE_OR_SCHOOL_BUS_ONLY: 'M - Class B or C Passenger Vehicle or School Bus Only',
    N_CLASS_C_PASSENGER_VEHICLE_OR_SCHOOL_BUS_ONLY: 'N - Class C Passenger Vehicle or School Bus Only',
    V_MEDICAL_VARIANCE: 'V - Medical Variance',
  },

  unions: {
    '17': '17',
    '42': '42',
    '70': '70',
    '71': '71',
    '84': '84',
    '104': '104',
    '126': '126',
    '160': '160',
    '222': '222',
    '245': '245',
    '351': '351',
    '876': '876',
    '1249': '1249',
    '1319': '1319',
    '104 N': '104 N',
    OTHER_UNION: 'Other Union',
  },

  union_classifications: {
    '17': {
      N_A: 'N/A',
      L17_A_FOREMAN: '“A” Foreman',
      L17_C_FOREMAN: '“C” Foreman',
      L17_B_FOREMAN: '“B” Foreman',
      L17_LABOR_FOREMAN: 'Labor Foreman',
      L17_JOURNEYMAN_LINEMAN: 'Journeyman Lineman',
      L17_JOURNEYMAN_TECHNICIAN: 'Journeyman Technician',
      L17_LINEMAN_SPECIALIST: 'Lineman Specialist',
      L17_STATION_OPERATOR: 'Station Operator',
      L17_STATION_MAINTENANCE_TECHNICIAN: 'Station Maintenance Technician',
      L17_APPRENTICE_LINEMAN_1: 'Apprentice  Lineman 1',
      L17_APPRENTICE_LINEMAN_2: 'Apprentice  Lineman 2',
      L17_APPRENTICE_LINEMAN_3: 'Apprentice  Lineman 3',
      L17_APPRENTICE_LINEMAN_4: 'Apprentice  Lineman 4',
      L17_APPRENTICE_LINEMAN_5: 'Apprentice  Lineman 5',
      L17_APPRENTICE_LINEMAN_6: 'Apprentice  Lineman 6',
      L17_APPRENTICE_LINEMAN_7: 'Apprentice  Lineman 7',
      L17_COMBINATION_EARTH_BORING: 'Combination Earth Boring',
      L17_TRACTOR_OPERATOR: 'Tractor Operator',
      L17_POLE_HAUL_DRIVER: 'Pole Haul Driver',
      L17_TRUCK_DRIVER: 'Truck Driver',
      L17_MATERIAL_HANDLER: 'Material Handler',
      L17_POLE_HAUL_HELPER: 'Pole Haul Helper',
      L17_LINE_CREW_GROUNDMAN: 'Line Crew Groundman',
      L17_GROUNDMAN: 'Groundman',
    },

    '42': {
      N_A: 'N/A',
      L42_GENERAL_FOREMAN: 'General Foreman',
      L42_FOREMAN: 'Foreman',
      L42_LEAD_LINEMAN: 'Lead Lineman',
      L42_CABLE_SPLICER: 'Cable Splicer',
      L42_DYNAMITE_MAN: 'Dynamite Man',
      L42_LINEMAN: 'Lineman ',
      L42_APPRENTICE_1: 'Apprentice 1',
      L42_APPRENTICE_2: 'Apprentice 2',
      L42_APPRENTICE_3: 'Apprentice 3',
      L42_APPRENTICE_4: 'Apprentice 4',
      L42_APPRENTICE_5: 'Apprentice 5',
      L42_APPRENTICE_6: 'Apprentice 6',
      L42_APPRENTICE_7: 'Apprentice 7',
      L42_HEAVY_EQUIP_OPER: 'Heavy Equip. Oper.',
      L42_MAT_MAN_TT_DRIVER: 'Mat. Man, TT Driver',
      L42_EQUIP_OPER_F_MECH_A: 'Equip. Oper. / F. Mech. A',
      L42_DR_GROUNDMAN: 'Dr. Groundman',
      L42_FIELD_MECH_B: 'Field Mech. B',
      L42_FIELD_MECH_C: 'Field Mech. C',
      L42_GROUNDMAN_EXPERIENCED: 'Groundman Experienced',
      L42_FIRST_YEAR_GROUNDMAN: 'First Year Groundman',
      L42_FLAGMAN: 'Flagman',
    },

    '70': {
      N_A: 'N/A',
      L245_FOREMAN: 'Foreman',
      L245_SUB_FOREMAN: 'Sub-Foreman',
      L245_FOREMAN_HOLE_DIGGING: 'Foreman Hole Digging',
      L245_LINEMAN_JOURNEYMAN_: 'Lineman, Journeyman',
      L245_SUBSTATION_TECHNICIAN: 'Substation Technician',
      L245_TRANSMISSION_TECHNICIAN: 'Transmission Technician',
      L245_CABLE_SPLICER: 'Cable Splicer',
      L245_LINEMAN_SUBSTANCIAL_APPRENTICE_1: 'Lineman & Substancial Apprentice 1',
      L245_LINEMAN_SUBSTANCIAL_APPRENTICE_2: 'Lineman & Substancial Apprentice 2',
      L245_LINEMAN_SUBSTANCIAL_APPRENTICE_3: 'Lineman & Substancial Apprentice 3',
      L245_LINEMAN_SUBSTANCIAL_APPRENTICE_4: 'Lineman & Substancial Apprentice 4',
      L245_LINEMAN_SUBSTANCIAL_APPRENTICE_5: 'Lineman & Substancial Apprentice 5',
      L245_LINEMAN_SUBSTANCIAL_APPRENTICE_6: 'Lineman & Substancial Apprentice 6',
      L245_LINEMAN_SUBSTANCIAL_APPRENTICE_7: 'Lineman & Substancial Apprentice 7',
      L245_TRANSMISSION_TECH_APPRENTICE_1: 'Transmission Tech Apprentice 1',
      L245_TRANSMISSION_TECH_APPRENTICE_2: 'Transmission Tech Apprentice 2',
      L245_TRANSMISSION_TECH_APPRENTICE_3: 'Transmission Tech Apprentice 3',
      L245_TRANSMISSION_TECH_APPRENTICE_4: 'Transmission Tech Apprentice 4',
      L245_EQUIPMENT_MECHANIC: 'Equipment Mechanic',
      L245_OPERATOR_C: "Operator 'C'",
      L245_OPERATOR_B: "Operator 'B'",
      L245_OPERATOR_A: "Operator 'A'",
      L245_LINE_TRUCK_W_AUGER: 'Line Truck w/auger',
      L245_GROUNDMAN_TRUCK_DRIVER: 'Groundman / Truck Driver',
    },

    '71': {
      N_A: 'N/A',
      L71_FOREMAN: 'Foreman',
      L71_SUB_FOREMAN: 'Sub-Foreman',
      L71_FOREMAN_HOLE_DIGGER: 'Foreman, Hole Digger',
      L71_LINEMAN_JOURNEYMAN: 'Lineman, Journeyman ',
      L71_SUBSTATION_TECHNICIAN: 'Substation Technician',
      L71_CABLE_SPLICER: 'Cable Splicer',
      L71_LINEMAN_SUBSTANCIAL_APPRENTICE_1: 'Lineman & Substancial Apprentice 1',
      L71_LINEMAN_SUBSTANCIAL_APPRENTICE_2: 'Lineman & Substancial Apprentice 2',
      L71_LINEMAN_SUBSTANCIAL_APPRENTICE_3: 'Lineman & Substancial Apprentice 3',
      L71_LINEMAN_SUBSTANCIAL_APPRENTICE_4: 'Lineman & Substancial Apprentice 4',
      L71_LINEMAN_SUBSTANCIAL_APPRENTICE_5: 'Lineman & Substancial Apprentice 5',
      L71_LINEMAN_SUBSTANCIAL_APPRENTICE_6: 'Lineman & Substancial Apprentice 6',
      L71_LINEMAN_SUBSTANCIAL_APPRENTICE_7: 'Lineman & Substancial Apprentice 7',
      L71_EQUIPMENT_MECHANIC_C: "Equipment Mechanic 'C'",
      L71_EQUIPMENT_MECHANIC_B: "Equipment Mechanic 'B'",
      L71_EQUIPMENT_MECHANIC_A: "Equipment Mechanic 'A'",
      L71_OPERATOR_C: "Operator 'C'",
      L71_OPERATOR_B: "Operator 'B'",
      L71_OPERATOR_A: "Operator 'A'",
      L71_LINE_TRUCK_W_AUGER: 'Line Truck w/auger',
      L71_GROUNDMAN_TRUCK_DRIVER: 'Groundman/Truck Driver',
    },

    '84': {
      N_A: 'N/A',
      L84_JOURNEYMAN_LINEMAN: 'Journeyman Lineman',
      L84_LEAD_LINEMAN: 'Lead Lineman',
      L84_FOREMAN: 'Foreman',
      L84_GENERAL_FOREMAN: 'General Foreman',
      L84_HEAVY_EQP_OPERATOR: 'Heavy Eqp. Operator',
      L84_EQUIPMENT_OPERATOR: 'Equipment Operator',
      L84_TRUCK_OPERATOR: 'Truck Operator',
      L84_GROUNDMAN_OVER_6_MNTH: 'Groundman-over 6 mnth. ',
      L84_APPRENTICE_1: 'Apprentice 1',
      L84_APPRENTICE_2: 'Apprentice 2',
      L84_APPRENTICE_3: 'Apprentice 3',
      L84_APPRENTICE_4: 'Apprentice 4',
      L84_APPRENTICE_5: 'Apprentice 5',
      L84_APPRENTICE_6: 'Apprentice 6',
      L84_APPRENTICE_7: 'Apprentice 7',
    },

    '104': {
      N_A: 'N/A',
      L104_GENERAL_FOREMAN: 'General Foreman',
      L104_FOREMAN: 'Foreman',
      L104_HEAD_LINEMAN: 'Head Lineman',
      L104_LINEMAN_SPLICER: 'Lineman / Splicer',
      L104_APPRENTICE_1: 'Apprentice 1',
      L104_APPRENTICE_2: 'Apprentice 2',
      L104_APPRENTICE_3: 'Apprentice 3',
      L104_APPRENTICE_4: 'Apprentice 4',
      L104_APPRENTICE_5: 'Apprentice 5',
      L104_APPRENTICE_6: 'Apprentice 6',
      L104_APPRENTICE_7: 'Apprentice 7',
      L104_EQUIPMENT_OPERATOR_A: 'Equipment Operator “A”',
      L104_EQUIPMENT_OPERATOR_B: 'Equipment Operator “B”',
      L104_CABLEMAN: 'Cableman',
      L104_DRIVER_GROUNDMAN_CDL: 'Driver Groundman CDL',
      L104_INEXPERIENCED_DRIVER_GROUNDMAN: 'Inexperienced Driver Groundman',
      L104_CABLE_TECHNICIAN: 'Cable Technician',
      L104_GROUNDMAN: 'Groundman',
      L104_INEXPERIENCED_GROUNDMAN: 'Inexperienced Groundman',
    },

    '126': {
      N_A: 'N/A',
      L126_GENERAL_FOREMAN: 'General Foreman',
      L126_FOREMAN: 'Foreman',
      L126_SMALL_JOB_FOREMAN: 'Small Job Foreman',
      L126_DUCT_FOREMAN: 'Duct Foreman',
      L126_JOURNEYMAN_LINEMAN: 'Journeyman Lineman',
      L126_JOURNEYMAN_TECHNICAL: 'Journeyman Technical',
      L126_DYNAMITE_MAN: 'Dynamite Man',
      L126_APPRENTICE_1: 'Apprentice 1',
      L126_APPRENTICE_2: 'Apprentice 2',
      L126_APPRENTICE_3: 'Apprentice 3',
      L126_APPRENTICE_4: 'Apprentice 4',
      L126_APPRENTICE_5: 'Apprentice 5',
      L126_APPRENTICE_6: 'Apprentice 6',
      L126_APPRENTICE_7: 'Apprentice 7',
      L126_A_OPERATOR: '“A” Operator',
      L126_B_OPERATOR: '“B” Operator',
      L126_EQUIPMENT_OPERATOR: 'Equipment Operator',
      L126_LINE_EQUIPMENT_MECHANIC: 'Line Equipment Mechanic',
      L126_DIRECTION_DRILLER: 'Direction Driller',
      L126_LOCATOR: 'Locator',
      L126_SERVICEMAN: 'Serviceman',
      L126_WINCH_TRUCK_OPERATOR: 'Winch Truck Operator',
      L126_LINE_TRUCK_OPERATOR: 'Line Truck Operator',
      L126_GROUNDHAND: 'Groundhand',
      L126_STREET_LIGHT_REPAIRMAN: 'Street Light Repairman',
      L126_METER_PERSON: 'Meter Person ',
      L126_FLAG_PERSON: 'Flag Person',
    },

    '160': {
      N_A: 'N/A',
      L160_GENERAL_FOREMAN: 'General Foreman',
      L160_FOREMAN: 'Foreman',
      L160_JOURNEYMAN_LINEMAN: 'Journeyman Lineman',
      L160_APPRENTICE_1: 'Apprentice 1',
      L160_APPRENTICE_2: 'Apprentice 2',
      L160_APPRENTICE_3: 'Apprentice 3',
      L160_APPRENTICE_4: 'Apprentice 4',
      L160_APPRENTICE_5: 'Apprentice 5',
      L160_APPRENTICE_6: 'Apprentice 6',
      L160_APPRENTICE_7: 'Apprentice 7',
      L160_JOURNEYMAN_TECHNICIAN: 'Journeyman Technician',
      L160_CABLE_SPLICER: 'Cable Splicer',
      L160_DYNAMITER: 'Dynamiter',
      L160_SPECIAL_EQUIPMENT_OPERATOR: 'Special Equipment Operator',
      L160_EQUIPMENT_OPERATOR: 'Equipment Operator',
      L160_TRAINEE_OPERATOR_1: 'Trainee Operator 1',
      L160_TRAINEE_OPERATOR_2: 'Trainee Operator 2',
      L160_SENIOR_GROUNDMAN_TRUCK_DRIVER: 'Senior Groundman/Truck Driver',
      L160_GROUNDMAN: 'Groundman',
      L160_GROUNDMAN_POLE_TREATING_SPECIALIST: 'Groundman - Pole Treating Specialist',
      L160_GROUNDMAN_POLE_TREATING_INSPECTOR: 'Groundman - Pole Treating Inspector',
      L160_GROUNDMAN_POLE_TREATING_TRUCK_DRIVER: 'Groundman - Pole Treating Truck Driver',
      L160_STREET_LIGHT_TECHNICIAN: 'Street Light Technician',
    },

    '222': {
      N_A: 'N/A',
      L222_GENERAL_FOREMAN: 'General Foreman',
      L222_FOREMAN: 'Foreman',
      L222_JOURNEYMAN_LINEMAN: 'Journeyman Lineman',
      L222_HEAVY_EQUIP_OP: 'Heavy Equip Op',
      L222_OPERATOR: 'Operator',
      L222_CABLE_SPLICER: 'Cable Splicer',
      L222_GROUNDMAN_TRUCK_DRIVER: 'Groundman / Truck Driver',
      L222_APPRENTICE_LINEMAN_1: 'Apprentice  Lineman 1',
      L222_APPRENTICE_LINEMAN_2: 'Apprentice  Lineman 2',
      L222_APPRENTICE_LINEMAN_3: 'Apprentice  Lineman 3',
      L222_APPRENTICE_LINEMAN_4: 'Apprentice  Lineman 4',
      L222_APPRENTICE_LINEMAN_5: 'Apprentice  Lineman 5',
      L222_APPRENTICE_LINEMAN_6: 'Apprentice  Lineman 6',
      L222_APPRENTICE_LINEMAN_7: 'Apprentice  Lineman 7',
    },

    '245': {
      N_A: 'N/A',
      L245_FOREMAN: 'Foreman',
      L245_SUB_FOREMAN: 'Sub-Foreman',
      L245_FOREMAN_HOLE_DIGGER: 'Foreman, Hole Digger',
      L245_LINEMAN_JOURNEYMAN: 'Lineman, Journeyman ',
      L245_SUBSTATION_TECHNICIAN: 'Substation Technician',
      L245_CABLE_SPLICER: 'Cable Splicer',
      L245_LINEMAN_SUBSTANCIAL_APPRENTICE_1: 'Lineman & Substancial Apprentice 1',
      L245_LINEMAN_SUBSTANCIAL_APPRENTICE_2: 'Lineman & Substancial Apprentice 2',
      L245_LINEMAN_SUBSTANCIAL_APPRENTICE_3: 'Lineman & Substancial Apprentice 3',
      L245_LINEMAN_SUBSTANCIAL_APPRENTICE_4: 'Lineman & Substancial Apprentice 4',
      L245_LINEMAN_SUBSTANCIAL_APPRENTICE_5: 'Lineman & Substancial Apprentice 5',
      L245_LINEMAN_SUBSTANCIAL_APPRENTICE_6: 'Lineman & Substancial Apprentice 6',
      L245_LINEMAN_SUBSTANCIAL_APPRENTICE_7: 'Lineman & Substancial Apprentice 7',
      L245_EQUIPMENT_MECHANIC_C: "Equipment Mechanic 'C'",
      L245_EQUIPMENT_MECHANIC_B: "Equipment Mechanic 'B'",
      L245_EQUIPMENT_MECHANIC_A: "Equipment Mechanic 'A'",
      L245_OPERATOR_C: "Operator 'C'",
      L245_OPERATOR_B: "Operator 'B'",
      L245_OPERATOR_A: "Operator 'A'",
      L245_LINE_TRUCK_W_AUGER: 'Line Truck w/auger',
      L245_GROUNDMAN_TRUCK_DRIVER: 'Groundman/Truck Driver',
    },

    '351': {
      N_A: 'N/A',
      L351_GENERAL_FOREMAN: 'General Foreman',
      L351_FOREMAN: 'Foreman',
      L351_SMALL_JOB_FOREMAN: 'Small Job Foreman',
      L351_HEAVY_EQUIPMENT_OPERATOR: 'Heavy Equipment Operator',
      L351_CABLE_SPLICER: 'Cable Splicer',
      L351_JOURNEYMAN_LINEMAN: 'Journeyman Lineman',
      L351_JOURNEYMAN_WELDER: 'Journeyman Welder',
      L351_JOURNEYMAN_PAINTER: 'Journeyman Painter',
      L351_LIGHT_EQUIPMENT_OPERATOR: 'Light Equipment Operator',
      L351_GROUNDMAN_TRUCK_DRIVER: 'Groundman Truck Driver',
      L351_GROUNDMAN_3RD_YEAR: 'Groundman 3rd Year',
      L351_GROUNDMAN_2ND_YEAR: 'Groundman 2nd Year',
      L351_GROUNDMAN_1ST_YEAR: 'Groundman 1st Year',
      L351_FLAGMAN: 'Flagman',
      L351_APPRENTICE_JOURNEYMAN_1: 'Apprentice Journeyman 1',
      L351_APPRENTICE_JOURNEYMAN_2: 'Apprentice Journeyman 2',
      L351_APPRENTICE_JOURNEYMAN_3: 'Apprentice Journeyman 3',
      L351_APPRENTICE_JOURNEYMAN_4: 'Apprentice Journeyman 4',
      L351_APPRENTICE_JOURNEYMAN_5: 'Apprentice Journeyman 5',
      L351_APPRENTICE_JOURNEYMAN_6: 'Apprentice Journeyman 6',
      L351_APPRENTICE_JOURNEYMAN_7: 'Apprentice Journeyman 7',
    },

    '876': {
      N_A: 'N/A',
      L876_FOREMAN_IN_CHANGE_OF_LINE_OR_SUBSTATION: 'Foreman - In change of line or substation',
      L876_FOREMAN_IN_CHANGE_OF_POLE_SETTING: 'Foreman - In change of pole setting',
      L876_SUB_FOREMAN: 'Sub-Foreman',
      L876_CABLE_SPLICER: 'Cable Splicer',
      L876_LINEMAN_SERVICE_WORKER: 'Lineman Service Worker',
      L876_JOURNEYMAN_LINEMAN: 'Journeyman Lineman',
      L876_JOURNEYMAN_SUBSTATION_TECHNICIAN: 'Journeyman Substation technician',
      L876_APPRENTICE_LINEMAN_1: 'Apprentice  Lineman 1',
      L876_APPRENTICE_LINEMAN_2: 'Apprentice  Lineman 2',
      L876_APPRENTICE_LINEMAN_3: 'Apprentice  Lineman 3',
      L876_APPRENTICE_LINEMAN_4: 'Apprentice  Lineman 4',
      L876_APPRENTICE_LINEMAN_5: 'Apprentice  Lineman 5',
      L876_APPRENTICE_LINEMAN_6: 'Apprentice  Lineman 6',
      L876_APPRENTICE_LINEMAN_7: 'Apprentice  Lineman 7',
      L876_OPERATOR_GROUNDMAN: 'Operator / Groundman',
      L876_LIGHT_EQUIPMENT_OPERATOR_GROUNDMAN_TRUCK_DRIVER_GROUNDMAN: 'Light Equipment Operator /  Groundman / Truck Driver Groundman',
      L876_GROUNDMAN_TRUCK_DRIVER_GROUNDMAN: 'Groundman-Truck Driver-Groundman',
      L876_UNDERGROUND_DISTR_SUB_TECH_1ST: 'Underground Distr. Sub-Tech 1st',
      L876_UNDERGROUND_DISTR_SUB_TECH: 'Underground Distr. Sub-Tech ',
    },

    '1249': {
      N_A: 'N/A',
      L1249_GENERAL_FOREMAN: 'General Foreman',
      L1249_FOREMAN: 'Foreman',
      L1249_WORKING_FOREMAN: 'Working Foreman',
      L1249_JOURNEYMAN_LINEMAN: 'Journeyman Lineman',
      L1249_APPRENTICE_1: 'Apprentice 1',
      L1249_APPRENTICE_2: 'Apprentice 2',
      L1249_APPRENTICE_3: 'Apprentice 3',
      L1249_APPRENTICE_4: 'Apprentice 4',
      L1249_APPRENTICE_5: 'Apprentice 5',
      L1249_APPRENTICE_6: 'Apprentice 6',
      L1249_APPRENTICE_7: 'Apprentice 7',
      L1249_GR_DIGGING_MACHINE_OPER: 'Gr. Digging Machine Oper.',
      L1249_CHIEF_MECHANIC: 'Chief Mechanic',
      L1249_MECHANIC_FIRST_CLASS: 'Mechanic First Class',
      L1249_MECHANIC_HELPER: 'Mechanic Helper',
      L1249_GROUNDMAN_TRUCK_DRIVER_TT_EO_W_CDL_A: 'Groundman Truck Driver (TT) / EO W/CDL A',
      L1249_GROUNDMAN_TRUCK_DRIVER_EO_W_CDL_B: 'Groundman Truck Driver / EO W/CDL B',
      L1249_GROUNDMAN: 'Groundman ',
      L1249_FLAGMAN: 'Flagman',
    },

    '1319': {
      N_A: 'N/A',
      L1319_GENERAL_FOREMAN: 'General Foreman',
      L1319_FOREMAN: 'Foreman',
      L1319_SMALL_JOB_FOREMAN: 'Small Job Foreman',
      L1319_JOURNEYMAN_LINEMAN: 'Journeyman Lineman',
      L1319_APPRENTICE_1: 'Apprentice 1',
      L1319_APPRENTICE_2: 'Apprentice 2',
      L1319_APPRENTICE_3: 'Apprentice 3',
      L1319_APPRENTICE_4: 'Apprentice 4',
      L1319_APPRENTICE_5: 'Apprentice 5',
      L1319_APPRENTICE_6: 'Apprentice 6',
      L1319_APPRENTICE_7: 'Apprentice 7',
      L1319_EQUIPMENT_OPERATOR_A: 'Equipment Operator A',
      L1319_EQUIPMENT_OPERATOR_B: 'Equipment Operator B',
      L1319_EQUIPMENT_OPERATOR: 'Equipment Operator',
      L1319_VAC_TR_DIR_BORING: 'Vac Tr / Dir. Boring',
      L1319_DYNAMIT_MAN: 'Dynamit Man',
      L1319_GR_TRUCK_DRIVER_W: 'Gr. Truck Driver (W)',
      L1319_GROUNDMAN_TRUCK_DR: 'Groundman Truck Dr.',
      L1319_GROUNDMAN: 'Groundman',
      L1319_GROUNDMAN_1ST_YEAR: 'Groundman - 1st Year',
      L1319_FLAGMAN: 'Flagman',
      L1319_LINE_EQUIP_MECH_WELDER: 'Line Equip. Mech. / Welder',
      L1319_EQ_SERV_MECH_HELPER: 'Eq. Serv. / Mech. Helper',
    },

    '104 N': {
      N_A: 'N/A',
      L104N_GENERAL_FOREMAN: 'General Foreman',
      L104N_FOREMAN: 'Foreman',
      L104N_HEAD_LINEMAN: 'Head Lineman',
      L104N_JOURNEYMAN_LINEMAN_SPLICER: 'Journeyman Lineman / Splicer',
      L104N_APPRENTICE_1: 'Apprentice 1',
      L104N_APPRENTICE_2: 'Apprentice 2',
      L104N_APPRENTICE_3: 'Apprentice 3',
      L104N_APPRENTICE_4: 'Apprentice 4',
      L104N_APPRENTICE_5: 'Apprentice 5',
      L104N_APPRENTICE_6: 'Apprentice 6',
      L104N_APPRENTICE_7: 'Apprentice 7',
      L104N_EQUIPMENT_OPERATOR_A: 'Equipment Operator “A”',
      L104N_EQUIPMENT_OPERATOR_B: 'Equipment Operator “B”',
      L104N_CABLEMAN: 'Cableman',
      L104N_DRIVER_GROUNDMAN_CDL: 'Driver Groundman CDL',
      L104N_INEXPERIENCED_DRIVER_GROUNDMAN: 'Inexperienced Driver Groundman',
      L104N_CABLE_TECHNICIAN: 'Cable Technician',
      L104N_GROUNDMAN: 'Groundman',
      L104N_INEXPERIENCED_GROUNDMAN: 'Inexperienced Groundman',
    },

    OTHER_UNION: {
      N_A: 'N/A',
      LOTHER_JOURNEYMAN_LINEMAN: 'Journeyman Lineman',
      LOTHER_APPRENTICE_1: 'Apprentice 1',
      LOTHER_APPRENTICE_2: 'Apprentice 2',
      LOTHER_APPRENTICE_3: 'Apprentice 3',
      LOTHER_APPRENTICE_4: 'Apprentice 4',
      LOTHER_APPRENTICE_5: 'Apprentice 5',
      LOTHER_APPRENTICE_6: 'Apprentice 6',
      LOTHER_APPRENTICE_7: 'Apprentice 7',
      LOTHER_EQUIPMENT_OPERATOR: 'Equipment Operator',
      LOTHER_GROUNDMAN: 'Groundman',
    },
  },

  timesheet_vehicle_classifications: {
    'Avangrid-CMP': {
      N_A: 'N/A',
      BUCKET_TRUCK_45_TO_55_M: "Bucket Truck 45' to 55' (MH)",
      BUCKET_TRUCK_MORE_55_MH: "Bucket Truck  >55' (MH)",
      DIGGER_DERRICK_47_SHIVE_HT: "Digger Derrick - 47' SHIVE HT.",
      DIGGER_DERRICK_MORE_47_SHIVE_HT: "Digger Derrick - >47' SHIVE HT.",
      MECHANIC_SERVICE_TRUCK_WITH_BOOM: 'Mechanic  / Service Truck (with boom)',
      PICK_UP_TRUCK_1_2_TON_4X4: 'Pick up Truck 1/2 ton 4X4',
      PICK_UP_TRUCK_4X4_3_4_TON: 'Pick up Truck 4X4 3/4 ton',
      PICK_UP_TRUCK_4X4_1_TON: 'Pick up Truck 4X4 1 ton',
      TRAILER_POLE: 'Trailer - Pole',
      TRAILER_MATERIAL: 'Trailer - Material',
      TRAILER_UTILITY: 'Trailer - Utility',
      BYM_BACKYARD_MACHINE: 'BYM - backyard machine',
      ENCLOSED_TRAILER_24: "Enclosed Trailer 24'",
      MOBILE_COMMAND_STORM_RESPONSE_TRAILER_W_SEMI_TRACTOR: 'Mobile Command Storm Response Trailer w/ Semi Tractor',
    },
    'Avangrid-NYSEG': {
      N_A: 'N/A',
      BUCKET_TRUCK_45_TO_55_M: "Bucket Truck 45' to 55' (MH)",
      BUCKET_TRUCK_MORE_55_MH: "Bucket Truck  >55' (MH)",
      DIGGER_DERRICK_47_SHIVE_HT: "Digger Derrick - 47' SHIVE HT.",
      DIGGER_DERRICK_MORE_47_SHIVE_HT: "Digger Derrick - >47' SHIVE HT.",
      MECHANIC_SERVICE_TRUCK_WITH_BOOM: 'Mechanic  / Service Truck (with boom)',
      PICK_UP_TRUCK_1_2_TON_4X4: 'Pick up Truck 1/2 ton 4X4',
      PICK_UP_TRUCK_4X4_3_4_TON: 'Pick up Truck 4X4 3/4 ton',
      PICK_UP_TRUCK_4X4_1_TON: 'Pick up Truck 4X4 1 ton',
      TRAILER_POLE: 'Trailer - Pole',
      TRAILER_MATERIAL: 'Trailer - Material',
      TRAILER_UTILITY: 'Trailer - Utility',
      BYM_BACKYARD_MACHINE: 'BYM - backyard machine',
      ENCLOSED_TRAILER_24: "Enclosed Trailer 24'",
      MOBILE_COMMAND_STORM_RESPONSE_TRAILER_W_SEMI_TRACTOR: 'Mobile Command Storm Response Trailer w/ Semi Tractor',
    },
    'Avangrid-RGE': {
      N_A: 'N/A',
      BUCKET_TRUCK_45_TO_55_M: "Bucket Truck 45' to 55' (MH)",
      BUCKET_TRUCK_MORE_55_MH: "Bucket Truck  >55' (MH)",
      DIGGER_DERRICK_47_SHIVE_HT: "Digger Derrick - 47' SHIVE HT.",
      DIGGER_DERRICK_MORE_47_SHIVE_HT: "Digger Derrick - >47' SHIVE HT.",
      MECHANIC_SERVICE_TRUCK_WITH_BOOM: 'Mechanic  / Service Truck (with boom)',
      PICK_UP_TRUCK_1_2_TON_4X4: 'Pick up Truck 1/2 ton 4X4',
      PICK_UP_TRUCK_4X4_3_4_TON: 'Pick up Truck 4X4 3/4 ton',
      PICK_UP_TRUCK_4X4_1_TON: 'Pick up Truck 4X4 1 ton',
      TRAILER_POLE: 'Trailer - Pole',
      TRAILER_MATERIAL: 'Trailer - Material',
      TRAILER_UTILITY: 'Trailer - Utility',
      BYM_BACKYARD_MACHINE: 'BYM - backyard machine',
      ENCLOSED_TRAILER_24: "Enclosed Trailer 24'",
      MOBILE_COMMAND_STORM_RESPONSE_TRAILER_W_SEMI_TRACTOR: 'Mobile Command Storm Response Trailer w/ Semi Tractor',
    },
    'Avangrid-UI': {
      N_A: 'N/A',
      BUCKET_TRUCK_45_TO_55_M: "Bucket Truck 45' to 55' (MH)",
      BUCKET_TRUCK_MORE_55_MH: "Bucket Truck  >55' (MH)",
      DIGGER_DERRICK_47_SHIVE_HT: "Digger Derrick - 47' SHIVE HT.",
      DIGGER_DERRICK_MORE_47_SHIVE_HT: "Digger Derrick - >47' SHIVE HT.",
      MECHANIC_SERVICE_TRUCK_WITH_BOOM: 'Mechanic  / Service Truck (with boom)',
      PICK_UP_TRUCK_1_2_TON_4X4: 'Pick up Truck 1/2 ton 4X4',
      PICK_UP_TRUCK_4X4_3_4_TON: 'Pick up Truck 4X4 3/4 ton',
      PICK_UP_TRUCK_4X4_1_TON: 'Pick up Truck 4X4 1 ton',
      TRAILER_POLE: 'Trailer - Pole',
      TRAILER_MATERIAL: 'Trailer - Material',
      TRAILER_UTILITY: 'Trailer - Utility',
      BYM_BACKYARD_MACHINE: 'BYM - backyard machine',
      ENCLOSED_TRAILER_24: "Enclosed Trailer 24'",
      MOBILE_COMMAND_STORM_RESPONSE_TRAILER_W_SEMI_TRACTOR: 'Mobile Command Storm Response Trailer w/ Semi Tractor',
    },
    Eversource: {
      N_A: 'N/A',
      '1_2_TON_PICK_UP_TRUCK_2_X_4': '1/2 ton pick-up truck 2 x 4',
      '1_2_TON_PICK_UP_TRUCK_4_X_4': '1/2 ton pick-up truck 4 x 4',
      '10_TON_TRAILER': '10 Ton Trailer',
      '3_4_TON_PICK_UP_TRUCK_4_WHEEL_DRIVE': '3/4 Ton Pick-up Truck - 4 wheel drive',
      '9_TON_3X1_TRAILER': '9 Ton 3x1 Trailer',
      AIR_COMPRESSOR: 'Air Compressor',
      AIR_COMPRESSOR_WITH_TOOLS: 'Air Compressor with Tools',
      ATV_WITH_PULL_BEHIND_TRAILER: 'ATV with pull behind trailer',
      BOOM_TRUCK: 'Boom Truck',
      BUCKET_SQUIRT_BOOM: 'Bucket - Squirt Boom',
      BUCKET_TRUCK_55_ML: 'Bucket Truck - 55 ML',
      BUCKET_TRUCK_55MH: 'Bucket Truck - 55MH',
      BUCKET_TRUCK_65_FT: 'Bucket Truck - 65 ft',
      BULLDOZER_SMALL: 'Bulldozer - Small',
      COMPRESSOR_850_CFM: 'Compressor 850 CFM',
      DIGGER_CORNER_MOUNTED_: 'Digger (Corner Mounted)',
      DIGGER_DERRICK: 'Digger Derrick',
      DUMP_TRUCK: 'Dump Truck',
      FLATBED_TRUCK: 'Flatbed Truck',
      LIFT_GATE_MATERIAL_TRUCK: 'Lift Gate Material Truck',
      MATERIAL_TRAILER: 'Material Trailer',
      POLE_TRAILER: 'Pole Trailer',
      RACK_TRUCK_LARGE: 'Rack Truck - Large',
      ROCK_DRILL_WITH_TRUCK: 'Rock Drill with truck',
      SINGLE_ARBOR_TRAILER: 'Single Arbor Trailer',
      UTILITY_TRUCK_1_TON: 'Utility Truck - 1 Ton',
      WIRE_PULLER_TENSIONER: 'Wire Puller Tensioner',
      WIRE_TRAILER: 'Wire Trailer',
    },
    'National Grid': {
      N_A: 'N/A',
      PASSENGER_VEHICLE: 'Passenger Vehicle',
      '1_2_TON_PICKUP': '1/2 Ton Pickup',
      SUV_S: "SUV's",
      '3_4_TON_PICKUP_1_TON_PICKUP': '3/4 Ton Pickup/ 1 Ton Pickup',
      MECHANIC_S_TRUCK: "Mechanic's Truck",
      POLE_TRAILER_MULTIPLE: 'Pole Trailer multiple',
      CARGO_TRAILER_DOUBLE_AXLE: 'Cargo Trailer (double axle)',
      '50_BUCKET_TRUCK_MATERIAL_HANDLER': "50' Bucket Truck - Material Handler",
      '55_BUCKET_TRUCK_MATERIAL_HANDLER': "55' Bucket Truck-  Material Handler",
      DIGGER_DERRICK_47_4X2: "Digger Derrick 47' 4X2",
      DIGGER_DERRICK_BACKYARD_MACHINE: 'Digger Derrick Backyard Machine',
    },
    'PPL-PA': {
      N_A: 'N/A',
      PICKUP: 'Pickup',
      MECHANIC_TRUCK_UTILITY_TRUCK: 'Mechanic Truck (utility truck)',
      BUCKET_SQUIRT_BOOM: 'Bucket - Squirt Boom',
      BUCKET_TRUCK_55_AND_GREATER: 'Bucket Truck - 55 and greater',
      DIGGER_DERRICK: 'Digger Derrick',
      BACK_YARD_MACHINE: 'Back Yard Machine',
      MATERIAL_TRAILER: 'Material Trailer',
      POLE_TRAILER: 'Pole Trailer',
    },
    'PPL-RIE': {
      N_A: 'N/A',
      PICKUP: 'Pickup',
      MECHANIC_TRUCK_UTILITY_TRUCK: 'Mechanic Truck (utility truck)',
      BUCKET_SQUIRT_BOOM: 'Bucket - Squirt Boom',
      BUCKET_TRUCK_55_AND_GREATER: 'Bucket Truck - 55 and greater',
      DIGGER_DERRICK: 'Digger Derrick',
      BACK_YARD_MACHINE: 'Back Yard Machine',
      MATERIAL_TRAILER: 'Material Trailer',
      POLE_TRAILER: 'Pole Trailer',
    },
  },

  utility_vehicle_classifications: {
    'Avangrid-CMP': {
      N_A: 'N/A',
      BUCKET_LESS_55: "Bucket < = 55'",
      BUCKET_MORE_55: "Bucket > 55'",
      DIGGER_EARTH: 'Digger - Earth',
      DIGGER_LEDGE: 'Digger - Ledge',
      MECHANIC_TRUCK: 'Mechanic Truck',
      PICK_UP: 'Pick Up',
      SEDAN: 'Sedan',
      SKIDDER: 'Skidder',
      STAKE_RACK: 'Stake Rack',
      SUV: 'SUV',
      TRACK: 'Track',
      VAN: 'Van',
    },
    'Avangrid-NYSEG': {
      N_A: 'N/A',
      BUCKET_LESS_55: "Bucket < = 55'",
      BUCKET_MORE_55: "Bucket > 55'",
      DIGGER_EARTH: 'Digger - Earth',
      DIGGER_LEDGE: 'Digger - Ledge',
      MECHANIC_TRUCK: 'Mechanic Truck',
      PICK_UP: 'Pick Up',
      SEDAN: 'Sedan',
      SKIDDER: 'Skidder',
      STAKE_RACK: 'Stake Rack',
      SUV: 'SUV',
      TRACK: 'Track',
      VAN: 'Van',
    },
    'Avangrid-RGE': {
      N_A: 'N/A',
      BUCKET_LESS_55: "Bucket < = 55'",
      BUCKET_MORE_55: "Bucket > 55'",
      DIGGER_EARTH: 'Digger - Earth',
      DIGGER_LEDGE: 'Digger - Ledge',
      MECHANIC_TRUCK: 'Mechanic Truck',
      PICK_UP: 'Pick Up',
      SEDAN: 'Sedan',
      SKIDDER: 'Skidder',
      STAKE_RACK: 'Stake Rack',
      SUV: 'SUV',
      TRACK: 'Track',
      VAN: 'Van',
    },
    'Avangrid-UI': {
      N_A: 'N/A',
      BUCKET_LESS_55: "Bucket < = 55'",
      BUCKET_MORE_55: "Bucket > 55'",
      DIGGER_EARTH: 'Digger - Earth',
      DIGGER_LEDGE: 'Digger - Ledge',
      MECHANIC_TRUCK: 'Mechanic Truck',
      PICK_UP: 'Pick Up',
      SEDAN: 'Sedan',
      SKIDDER: 'Skidder',
      STAKE_RACK: 'Stake Rack',
      SUV: 'SUV',
      TRACK: 'Track',
      VAN: 'Van',
    },
    Eversource: {
      N_A: 'N/A',
      EVERSOURCE_PROVIDED_BUCKET: 'Eversource Provided Bucket',
      BUCKET_TRUCK: 'Bucket Truck',
      DIGGER_DERRICK: 'Digger/Derrick',
      PICK_UP: 'Pick Up',
      TRANSMISSION_BUCKET: 'Transmission Bucket',
      CAR_VAN_SUV: 'Car/Van/SUV',
      DUMP_TRUCK: 'Dump Truck',
      MECHANIC_TRUCK: 'Mechanic Truck',
      OFF_ROAD_DIGGER: 'Off Road Digger',
      OFF_ROAD_OTHER: 'Off Road Other',
      OTHER_EQUIPMENT: 'Other Equipment',
      TRAILER_POLE: 'Trailer-Pole',
      BACKHOE: 'Backhoe',
      OFF_ROAD_BUCKET: 'Off Road Bucket',
      DUMP_TRUCK_W__BACKHOE: 'Dump Truck w/ Backhoe',
      MS_VAN: 'MS Van',
      I_R_TRUCK: 'I&R Truck',
    },
    'National Grid': {
      N_A: 'N/A',
      BUCKET_50_BUCKET_TRUCK: "Bucket:50' Bucket Truck",
      BUCKET_50_BUCKET_TRUCK_MATERIAL_HANDLER: "Bucket:50' Bucket Truck - Material Handler",
      BUCKET_55_BUCKET_TRUCK: "Bucket:55' Bucket Truck",
      BUCKET_55_BUCKET_TRUCK_MATERIAL_HANDLER: "Bucket:55' Bucket Truck-  Material Handler",
      BUCKET_60_BUCKET_TRUCK: "Bucket:60' Bucket Truck",
      BUCKET_60_BUCKET_TRUCK_MATERIAL_HANDLER: "Bucket:60' Bucket Truck Material Handler",
      BUCKET_65_70_BUCKET_TRUCK: "Bucket:65-70' Bucket Truck",
      BUCKET_65_70_BUCKET_TRUCK_MATERIAL_HANDLER: "Bucket:65-70' Bucket Truck - Material Handler",
      DIGGER_DIGGER_DERRICK_45_4X2: "Digger:Digger Derrick 45' 4X2",
      DIGGER_DIGGER_DERRICK_45_4X4_TRACK: "Digger:Digger Derrick 45' 4X4 / Track",
      DIGGER_DIGGER_DERRICK_BACKYARD_MACHINE: 'Digger:Digger Derrick Backyard Machine',
      PICKUP_1_2_TON_PICK_UP: 'Pickup:1-2 Ton Pick Up',
      PICKUP_3_4_TON_PICKUP_1_TON_PICKUP: 'Pickup:3/4 Ton Pickup/ 1 Ton Pickup',
      PICKUP_MECHANIC_S_TRUCK: "Pickup:Mechanic's Truck",
      PICKUP_PLOWS_PICK_UP_TRUCKS: 'Pickup:Plows (Pick up trucks)',
      PICKUP_TOOL_TRUCK: 'Pickup:Tool Truck',
      MISCELLANEOUS_3_AXLE_FLAT_BED: 'Miscellaneous:3- Axle Flat Bed',
      MISCELLANEOUS_CARGO_TRAILER_DOUBLE_AXLE: 'Miscellaneous:Cargo Trailer (double axle)',
      MISCELLANEOUS_CARGO_TRAILER_SINGLE_AXLE: 'Miscellaneous:Cargo Trailer (Single Axle)',
      MISCELLANEOUS_ELECTRICIAN_S_VAN: "Miscellaneous:Electrician's Van",
      MISCELLANEOUS_HOT_STICK_TRAILER_115_KV: 'Miscellaneous:Hot Stick Trailer (<115 kV)',
      MISCELLANEOUS_HOT_STICK_TRAILER_138_161_KV: 'Miscellaneous:Hot Stick Trailer (138-161 kV)',
      MISCELLANEOUS_HOT_STICK_TRAILER_345_KV: 'Miscellaneous:Hot Stick Trailer (345 kV)',
      MISCELLANEOUS_PASSENGER_VEHICLE_1_2_TON_PICKUP_AND_SUV_S: "Miscellaneous:Passenger Vehicle, 1/2 Ton Pickup and SUV's",
      MISCELLANEOUS_POLE_TRAILER_MULTIPLE: 'Miscellaneous:Pole Trailer multiple',
      MISCELLANEOUS_POLE_TRAILER_SINGLE: 'Miscellaneous:Pole Trailer single',
      MISCELLANEOUS_STAKERACK_BODY: 'Miscellaneous:Stakerack Body',
      MISCELLANEOUS_WIRE_CABLE_REEL_TRAILER_DUAL_ARBOR_BAR: 'Miscellaneous:Wire / Cable Reel Trailer - Dual arbor bar',
      MISCELLANEOUS_WIRE_CABLE_REEL_TRAILER_SELF_WINDING: 'Miscellaneous:Wire / Cable Reel Trailer - Self winding',
      MISCELLANEOUS_WIRE_CABLE_REEL_TRAILER_SINGLE_ARBOR_BAR: 'Miscellaneous:Wire / Cable Reel Trailer - Single arbor bar',
      MISCELLANEOUS_WIRE_CABLE_REEL_TRAILER_FOUR_ARBOR_BAR: 'Miscellaneous:Wire / Cable Reel Trailer- Four arbor bar',
      MISCELLANEOUS_WIRE_CABLE_REEL_TRAILER_TRIPLE_ARBOR_BAR: 'Miscellaneous:Wire / Cable Reel Trailer- Triple arbor bar',
      MISCELLANEOUS_WIRE_PULLER_TENSIONER_V_GROOVE_PULLER: 'Miscellaneous:Wire Puller / Tensioner V-Groove puller',
      MISCELLANEOUS_WIRE_PULLER_FOUR_DRUM_6000_IBS_OR_LESS: 'Miscellaneous:Wire Puller Four Drum 6000 Ibs or less',
      MISCELLANEOUS_WIRE_PULLER_FOUR_DRUM_ABOVE_6000_IBS: 'Miscellaneous:Wire Puller Four Drum above 6000 Ibs',
      MISCELLANEOUS_WIRE_PULLER_HARD_LINE_WIRE_3_DRUM: 'Miscellaneous:Wire Puller- Hard line wire- 3 Drum',
      MISCELLANEOUS_WIRE_PULLER_HARD_LINE_WIRE_SINGLE: 'Miscellaneous:Wire Puller- Hard line wire- single',
      MISCELLANEOUS_WIRE_PULLER_LESS_THAN_10000_IBS_SINGLE_DRUM: 'Miscellaneous:Wire Puller Less than 10,000 Ibs (Single Drum)',
    },
    'PPL-PA': {
      N_A: 'N/A',
      BUCKET_TRUCK: 'Bucket Truck',
      BUCKET_TRUCK_4X4: 'Bucket Truck 4x4',
      LINE_TRUCK: 'Line Truck',
      LINE_TRUCK_4X4: 'Line Truck 4x4',
      PICK_UP: 'Pick-up',
      REAR_PROPERTY_MACHINE: 'Rear Property Machine',
      DIGGER_DERRICK: 'Digger Derrick',
      OTHER: 'Other',
    },
    'PPL-RIE': {
      N_A: 'N/A',
      BUCKET_TRUCK: 'Bucket Truck',
      BUCKET_TRUCK_4X4: 'Bucket Truck 4x4',
      LINE_TRUCK: 'Line Truck',
      LINE_TRUCK_4X4: 'Line Truck 4x4',
      PICK_UP: 'Pick-up',
      REAR_PROPERTY_MACHINE: 'Rear Property Machine',
      DIGGER_DERRICK: 'Digger Derrick',
      OTHER: 'Other',
    },
  },

  utility_fte_classifications: {
    'Avangrid-UI': {
      N_A: 'N_A',
      SUPERVISOR: 'Supervisor',
      NWGF: 'NWGF',
      WGF: 'WGF',
      NWF: 'NWF',
      WF: 'WF',
      JOURNEYMAN: 'Journeyman',
      APPRENTICE_7TH_YEAR: 'Apprentice 7th Year',
      APPRENTICE_6TH_YEAR: 'Apprentice 6th Year',
      APPRENTICE_5TH_YEAR: 'Apprentice 5th Year',
      APPRENTICE_4TH_YEAR: 'Apprentice 4th Year',
      APPRENTICE_3RD_YEAR: 'Apprentice 3rd Year',
      APPRENTICE_2ND_YEAR: 'Apprentice 2nd Year',
      APPRENTICE_1ST_YEAR: 'Apprentice 1st Year',
      EQUIPMENT_OPERATOR: 'Equipment Operator',
      SAFETY: 'Safety',
      FLEET_MECHANIC: 'Fleet Mechanic',
      LINEWORKER_1_C: 'Lineworker 1/C',
      LINEWORKER_2_C: 'Lineworker 2/C',
      LINEWORKER_3_C: 'Lineworker 3/C',
      LINE_APPRENTICE: 'Line Apprentice',
      LINE_HELPER: 'Line Helper',
      DAMAGE_ASSESSOR: 'Damage Assessor',
      WIRE_GUARD: 'Wire Guard',
      PATROLLER: 'Patroller',
      FLAGGER: 'Flagger',
    },
    'Avangrid-CMP': {
      N_A: 'N/A',
      SUPERVISOR: 'Supervisor',
      NWGF: 'NWGF',
      WGF: 'WGF',
      NWF: 'NWF',
      WF: 'WF',
      JOURNEYMAN: 'Journeyman',
      APPRENTICE_7TH_YEAR: 'Apprentice 7th Year',
      APPRENTICE_6TH_YEAR: 'Apprentice 6th Year',
      APPRENTICE_5TH_YEAR: 'Apprentice 5th Year',
      APPRENTICE_4TH_YEAR: 'Apprentice 4th Year',
      APPRENTICE_3RD_YEAR: 'Apprentice 3rd Year',
      APPRENTICE_2ND_YEAR: 'Apprentice 2nd Year',
      APPRENTICE_1ST_YEAR: 'Apprentice 1st Year',
      EQUIPMENT_OPERATOR: 'Equipment Operator',
      SAFETY: 'Safety',
      FLEET_MECHANIC: 'Fleet Mechanic',
      LINEWORKER_1_C: 'Lineworker 1/C',
      LINEWORKER_2_C: 'Lineworker 2/C',
      LINEWORKER_3_C: 'Lineworker 3/C',
      LINE_APPRENTICE: 'Line Apprentice',
      LINE_HELPER: 'Line Helper',
      DAMAGE_ASSESSOR: 'Damage Assessor',
      WIRE_GUARD: 'Wire Guard',
      PATROLLER: 'Patroller',
      FLAGGER: 'Flagger',
    },
    'Avangrid-NYSEG': {
      N_A: 'N/A',
      SUPERVISOR: 'Supervisor',
      NWGF: 'NWGF',
      WGF: 'WGF',
      NWF: 'NWF',
      WF: 'WF',
      JOURNEYMAN: 'Journeyman',
      APPRENTICE_7TH_YEAR: 'Apprentice 7th Year',
      APPRENTICE_6TH_YEAR: 'Apprentice 6th Year',
      APPRENTICE_5TH_YEAR: 'Apprentice 5th Year',
      APPRENTICE_4TH_YEAR: 'Apprentice 4th Year',
      APPRENTICE_3RD_YEAR: 'Apprentice 3rd Year',
      APPRENTICE_2ND_YEAR: 'Apprentice 2nd Year',
      APPRENTICE_1ST_YEAR: 'Apprentice 1st Year',
      EQUIPMENT_OPERATOR: 'Equipment Operator',
      SAFETY: 'Safety',
      FLEET_MECHANIC: 'Fleet Mechanic',
      LINEWORKER_1_C: 'Lineworker 1/C',
      LINEWORKER_2_C: 'Lineworker 2/C',
      LINEWORKER_3_C: 'Lineworker 3/C',
      LINE_APPRENTICE: 'Line Apprentice',
      LINE_HELPER: 'Line Helper',
      DAMAGE_ASSESSOR: 'Damage Assessor',
      WIRE_GUARD: 'Wire Guard',
      PATROLLER: 'Patroller',
      FLAGGER: 'Flagger',
    },
    'Avangrid-RGE': {
      N_A: 'N/A',
      SUPERVISOR: 'Supervisor',
      NWGF: 'NWGF',
      WGF: 'WGF',
      NWF: 'NWF',
      WF: 'WF',
      JOURNEYMAN: 'Journeyman',
      APPRENTICE_7TH_YEAR: 'Apprentice 7th Year',
      APPRENTICE_6TH_YEAR: 'Apprentice 6th Year',
      APPRENTICE_5TH_YEAR: 'Apprentice 5th Year',
      APPRENTICE_4TH_YEAR: 'Apprentice 4th Year',
      APPRENTICE_3RD_YEAR: 'Apprentice 3rd Year',
      APPRENTICE_2ND_YEAR: 'Apprentice 2nd Year',
      APPRENTICE_1ST_YEAR: 'Apprentice 1st Year',
      EQUIPMENT_OPERATOR: 'Equipment Operator',
      SAFETY: 'Safety',
      FLEET_MECHANIC: 'Fleet Mechanic',
      LINEWORKER_1_C: 'Lineworker 1/C',
      LINEWORKER_2_C: 'Lineworker 2/C',
      LINEWORKER_3_C: 'Lineworker 3/C',
      LINE_APPRENTICE: 'Line Apprentice',
      LINE_HELPER: 'Line Helper',
      DAMAGE_ASSESSOR: 'Damage Assessor',
      WIRE_GUARD: 'Wire Guard',
      PATROLLER: 'Patroller',
      FLAGGER: 'Flagger',
    },
    Eversource: {
      N_A: 'N/A',
      CONTRACTOR_LINE_LINEMAN: 'Contractor Line:Lineman',
      CONTRACTOR_LINE_LINE_FOREMAN: 'Contractor Line:Line Foreman',
      CONTRACTOR_LINE_LINE_APPRENTICE: 'Contractor Line:Line Apprentice',
      CONTRACTOR_LINE_LINE_EQUIPMENT_OPERATOR: 'Contractor Line:Line Equipment Operator',
      CONTRACTOR_LINE_LINE_GROUNDMAN: 'Contractor Line:Line Groundman',
      CONTRACTOR_LINE_LINE_CABLE_SPLICER: 'Contractor Line:Line Cable Splicer',
      CONTRACTOR_LINE_WORKING_FOREMAN: 'Contractor Line:Working Foreman',
      CONTRACTOR_SERVICE_LINE_FOREMAN: 'Contractor Service:Line Foreman',
      CONTRACTOR_SERVICE_LINEMAN: 'Contractor Service:Lineman',
      CONTRACTOR_SERVICE_SERVICE_CREW_ELECTRICIAN: 'Contractor Service:Service Crew Electrician',
      CONTRACTOR_SUPPORT_FIELD_SAFETY: 'Contractor Support:Field Safety',
      CONTRACTOR_SUPPORT_FIELD_SUPPORT: 'Contractor Support:Field Support',
      CONTRACTOR_SUPPORT_FLAGGER: 'Contractor Support:Flagger',
      CONTRACTOR_SUPPORT_LINE_FOREMAN: 'Contractor Support:Line Foreman',
      CONTRACTOR_SUPPORT_LINE_GENERAL_FOREMAN: 'Contractor Support:Line General Foreman',
      CONTRACTOR_SUPPORT_LINEMAN: 'Contractor Support:Lineman',
      CONTRACTOR_SUPPORT_TREE_GENERAL_FOREMAN: 'Contractor Support:Tree General Foreman',
      CONTRACTOR_SUPPORT_VEHICLE_MECHANIC: 'Contractor Support:Vehicle Mechanic',
      DAMAGE_APPRAISER_DAMAGE_ASSESSOR: 'Damage Appraiser:Damage Assessor',
      DAMAGE_APPRAISER_DRIVER: 'Damage Appraiser:Driver',
      TRANSMISSION_LINEMAN: 'Transmission:Lineman',
      TRANSMISSION_LINE_FOREMAN: 'Transmission:Line Foreman',
      TRANSMISSION_LINE_APPRENTICE: 'Transmission:Line Apprentice',
      TRANSMISSION_LINE_EQUIPMENT_OPERATOR: 'Transmission:Line Equipment Operator',
      TRANSMISSION_LINE_GROUNDMAN: 'Transmission:Line Groundman',
      TRANSMISSION_LINE_CABLE_SPLICER: 'Transmission:Line Cable Splicer',
      TRANSMISSION_WORKING_FOREMAN: 'Transmission:Working Foreman',
      WIRE_DOWN_WIRES_DOWN_GUARD: 'Wire Down:Wires Down Guard',
      CONTRACTOR_TREE_TREE_CLIMBER: 'Contractor Tree:Tree Climber',
      CONTRACTOR_TREE_TREE_FOREMAN: 'Contractor Tree:Tree Foreman',
      CONTRACTOR_TREE_TREE_GROUNDMAN: 'Contractor Tree:Tree Groundman',
    },
    'National Grid': {
      N_A: 'N/A',
      SUPERINTENDENT: 'Superintendent',
      GENERAL_FOREMAN: 'General Foreman',
      WORKING_FOREMAN: 'Working Foreman',
      FOREMAN: 'Foreman',
      LEAD_LINEMAN: 'Lead Lineman',
      JOURNEYMAN_LINEMAN: 'Journeyman Lineman',
      APPRENTICE_7: 'Apprentice 7',
      APPRENTICE_6: 'Apprentice 6',
      APPRENTICE_5: 'Apprentice 5',
      APPRENTICE_4: 'Apprentice 4',
      APPRENTICE_3: 'Apprentice 3',
      APPRENTICE_2: 'Apprentice 2',
      APPRENTICE_1: 'Apprentice 1',
      LINEMAN: 'Lineman',
      EQUIPMENT_OPERATOR: 'Equipment Operator',
      GROUNDSMAN: 'Groundsman',
      MECHANIC: 'Mechanic',
      SAFETY_PERSON: 'Safety Person',
    },
    'PPL-PA': {
      N_A: 'N/A',
      GENERAL_FOREMAN: 'General Foreman',
      FOREMAN: 'Foreman',
      SJ_FOREMAN: 'SJ Foreman',
      LINE_JL: 'Line JL',
      LINE_APP: 'Line App',
      LINE_APP7: 'Line App7',
      LINE_APP6: 'Line App6',
      LINE_APP5: 'Line App5',
      LINE_APP4: 'Line App4',
      LINE_APP3: 'Line App3',
      LINE_APP2: 'Line App2',
      LINE_APP1: 'Line App1',
      EQUIPMENT_OP: 'Equipment Op',
      EQUIPMENT_OP_A: 'Equipment Op A',
      ELECT_NET: 'Elect-Net',
      ELEC_SUB: 'Elec Sub',
      ELECT_URG: 'Elect URG',
      MATL_HNDLR: 'Matl Hndlr',
      LINE_GROUNDHAND: 'Line Groundhand',
      MECHANIC: 'Mechanic',
      ASSESSOR: 'Assessor',
      VEG: 'Veg',
    },
    'PPL-RIE': {
      N_A: 'N/A',
      GENERAL_FOREMAN: 'General Foreman',
      FOREMAN: 'Foreman',
      SJ_FOREMAN: 'SJ Foreman',
      LINE_JL: 'Line JL',
      LINE_APP: 'Line App',
      LINE_APP7: 'Line App7',
      LINE_APP6: 'Line App6',
      LINE_APP5: 'Line App5',
      LINE_APP4: 'Line App4',
      LINE_APP3: 'Line App3',
      LINE_APP2: 'Line App2',
      LINE_APP1: 'Line App1',
      EQUIPMENT_OP: 'Equipment Op',
      EQUIPMENT_OP_A: 'Equipment Op A',
      ELECT_NET: 'Elect-Net',
      ELEC_SUB: 'Elec Sub',
      ELECT_URG: 'Elect URG',
      MATL_HNDLR: 'Matl Hndlr',
      LINE_GROUNDHAND: 'Line Groundhand',
      MECHANIC: 'Mechanic',
      ASSESSOR: 'Assessor',
      VEG: 'Veg',
    },
  },

  timesheet_fte_classifications: {
    'Avangrid-CMP': {
      N_A: 'N/A',
      SUPERINTENDENT: 'Superintendent',
      SAFETY: 'Safety',
      GENERAL_FOREMAN: 'General Foreman',
      WORKING_FOREMAN: 'Working Foreman',
      JOURNEYMAN_LINEMAN: 'Journeyman Lineman',
      APPRENTICE_7_LINEMAN: 'Apprentice  7 Lineman',
      APPRENTICE_6_LINEMAN: 'Apprentice  6 Lineman',
      APPRENTICE_5_LINEMAN: 'Apprentice  5 Lineman',
      APPRENTICE_4_LINEMAN: 'Apprentice  4 Lineman',
      APPRENTICE_3_LINEMAN: 'Apprentice  3 Lineman',
      APPRENTICE_2_LINEMAN: 'Apprentice  2 Lineman',
      APPRENTICE_1_LINEMAN: 'Apprentice  1 Lineman',
      EQUIPMENT_OPERATOR: 'Equipment Operator',
      LINE_HELPER_GROUNDHAND: 'Line Helper (Groundhand)',
      FLEET_MECHANIC: 'Fleet Mechanic',
    },
    'Avangrid-NYSEG': {
      N_A: 'N/A',
      SUPERINTENDENT: 'Superintendent',
      SAFETY: 'Safety',
      GENERAL_FOREMAN: 'General Foreman',
      WORKING_FOREMAN: 'Working Foreman',
      JOURNEYMAN_LINEMAN: 'Journeyman Lineman',
      APPRENTICE_7_LINEMAN: 'Apprentice  7 Lineman',
      APPRENTICE_6_LINEMAN: 'Apprentice  6 Lineman',
      APPRENTICE_5_LINEMAN: 'Apprentice  5 Lineman',
      APPRENTICE_4_LINEMAN: 'Apprentice  4 Lineman',
      APPRENTICE_3_LINEMAN: 'Apprentice  3 Lineman',
      APPRENTICE_2_LINEMAN: 'Apprentice  2 Lineman',
      APPRENTICE_1_LINEMAN: 'Apprentice  1 Lineman',
      EQUIPMENT_OPERATOR: 'Equipment Operator',
      LINE_HELPER_GROUNDHAND: 'Line Helper (Groundhand)',
      FLEET_MECHANIC: 'Fleet Mechanic',
    },
    'Avangrid-RGE': {
      N_A: 'N/A',
      SUPERINTENDENT: 'Superintendent',
      SAFETY: 'Safety',
      GENERAL_FOREMAN: 'General Foreman',
      WORKING_FOREMAN: 'Working Foreman',
      JOURNEYMAN_LINEMAN: 'Journeyman Lineman',
      APPRENTICE_7_LINEMAN: 'Apprentice  7 Lineman',
      APPRENTICE_6_LINEMAN: 'Apprentice  6 Lineman',
      APPRENTICE_5_LINEMAN: 'Apprentice  5 Lineman',
      APPRENTICE_4_LINEMAN: 'Apprentice  4 Lineman',
      APPRENTICE_3_LINEMAN: 'Apprentice  3 Lineman',
      APPRENTICE_2_LINEMAN: 'Apprentice  2 Lineman',
      APPRENTICE_1_LINEMAN: 'Apprentice  1 Lineman',
      EQUIPMENT_OPERATOR: 'Equipment Operator',
      LINE_HELPER_GROUNDHAND: 'Line Helper (Groundhand)',
      FLEET_MECHANIC: 'Fleet Mechanic',
    },
    'Avangrid-UI': {
      N_A: 'N/A',
      SUPERINTENDENT: 'Superintendent',
      SAFETY: 'Safety',
      GENERAL_FOREMAN: 'General Foreman',
      WORKING_FOREMAN: 'Working Foreman',
      JOURNEYMAN_LINEMAN: 'Journeyman Lineman',
      APPRENTICE_7_LINEMAN: 'Apprentice  7 Lineman',
      APPRENTICE_6_LINEMAN: 'Apprentice  6 Lineman',
      APPRENTICE_5_LINEMAN: 'Apprentice  5 Lineman',
      APPRENTICE_4_LINEMAN: 'Apprentice  4 Lineman',
      APPRENTICE_3_LINEMAN: 'Apprentice  3 Lineman',
      APPRENTICE_2_LINEMAN: 'Apprentice  2 Lineman',
      APPRENTICE_1_LINEMAN: 'Apprentice  1 Lineman',
      EQUIPMENT_OPERATOR: 'Equipment Operator',
      LINE_HELPER_GROUNDHAND: 'Line Helper (Groundhand)',
      FLEET_MECHANIC: 'Fleet Mechanic',
    },
    Eversource: {
      N_A: 'N/A',
      GENERAL_FOREMAN: 'General Foreman',
      FOREMAN: 'Foreman',
      LEAD_LINEMAN: 'Lead Lineman',
      LINEMAN: 'Lineman',
      '7TH_STEP_APPRENTICE': '7th Step Apprentice',
      '6TH_STEP_APPRENTICE': '6th Step Apprentice',
      '5TH_STEP_APPRENTICE': '5th Step Apprentice',
      '4TH_STEP_APPRENTICE': '4th Step Apprentice',
      '3RD_STEP_APPRENTICE': '3rd Step Apprentice',
      '2ND_STEP_APPRENTICE': '2nd Step Apprentice',
      '1ST_STEP_APPRENTICE': '1st Step Apprentice',
      HEAVY_EQUIPMENT_OPERATOR: 'Heavy Equipment Operator',
      MATERIAL_MAN: 'Material Man',
      EQ_OPER_FIELD_MECH_A: 'Eq Oper/Field Mech A',
      DRIVER_GROUNDMAN: 'Driver Groundman',
      FIELD_MECHANIC_B: 'Field Mechanic B',
      FIELD_MECHANIC_C: 'Field Mechanic C',
      GROUNDMAN_EXPERIENCED: 'Groundman - experienced',
      GROUNDMAN_1ST_STEP: 'Groundman - 1st Step',
      FLAGMAN: 'Flagman',
      SAFETY: 'Safety',
      ADMINISTRATION: 'Administration',
    },
    'National Grid': {
      N_A: 'N/A',
      GENERAL_FOREMAN: 'GENERAL FOREMAN',
      FOREMAN: 'FOREMAN',
      HEAD_LINEMAN: 'HEAD LINEMAN',
      SPLICER: 'SPLICER',
      JOURNEYMAN_LINEMAN: 'JOURNEYMAN LINEMAN',
      APPRENTICE_PERIOD_7: 'APPRENTICE PERIOD 7',
      APPRENTICE_PERIOD_6: 'APPRENTICE PERIOD 6',
      APPRENTICE_PERIOD_5: 'APPRENTICE PERIOD 5',
      APPRENTICE_PERIOD_4: 'APPRENTICE PERIOD 4',
      APPRENTICE_PERIOD_3: 'APPRENTICE PERIOD 3',
      APPRENTICE_PERIOD_2: 'APPRENTICE PERIOD 2',
      APPRENTICE_PERIOD_1: 'APPRENTICE PERIOD 1',
      EQUIPMENT_OPERATOR_A: 'EQUIPMENT OPERATOR A',
      EQUIPMENT_OPERATOR_B: 'EQUIPMENT OPERATOR B',
      CABLEMAN: 'CABLEMAN',
      CABLE_TECHNICIAN: 'CABLE TECHNICIAN',
      DRIVER_GROUNDMAN_CDL: 'DRIVER GROUNDMAN CDL',
      GROUNDMAN: 'GROUNDMAN',
      GROUNDMAN_INEXPERIENCED: 'GROUNDMAN INEXPERIENCED',
      MATERIAL_PERSON: 'MATERIAL PERSON',
      MECHANIC: 'MECHANIC',
      SAFETY_PERSON: 'SAFETY PERSON',
    },
    'PPL-PA': {
      N_A: 'N/A',
      GENERAL_FOREMAN: 'General Foreman',
      FOREMAN_NON_WORKING: 'Foreman (non working)',
      LEAD_LINEMAN_WORKING_FOREMAN: 'Lead Lineman (working foreman)',
      LINEMAN: 'Lineman',
      '7TH_STEP_APPRENTICE': '7th Step Apprentice',
      '6TH_STEP_APPRENTICE': '6th Step Apprentice',
      '5TH_STEP_APPRENTICE': '5th Step Apprentice',
      '4TH_STEP_APPRENTICE': '4th Step Apprentice',
      '3RD_STEP_APPRENTICE': '3rd Step Apprentice',
      '2ND_STEP_APPRENTICE': '2nd Step Apprentice',
      '1ST_STEP_APPRENTICE': '1st Step Apprentice',
      HEAVY_EQUIPMENT_OPERATOR: 'Heavy Equipment Operator',
      MATERIAL_MAN: 'Material Man',
      EQ_OPER_FIELD_MECH_A: 'Eq Oper/Field Mech A',
      DRIVER_GROUNDMAN_CDL_A: 'Driver Groundman (CDL A)',
      FIELD_MECHANIC: 'Field Mechanic',
      GROUNDMAN: 'Groundman',
      FLAGMAN: 'Flagman',
      SAFETY: 'Safety',
      ADMINISTRATION: 'Administration',
    },
    'PPL-RIE': {
      N_A: 'N/A',
      GENERAL_FOREMAN: 'General Foreman',
      FOREMAN_NON_WORKING: 'Foreman (non working)',
      LEAD_LINEMAN_WORKING_FOREMAN: 'Lead Lineman (working foreman)',
      LINEMAN: 'Lineman',
      '7TH_STEP_APPRENTICE': '7th Step Apprentice',
      '6TH_STEP_APPRENTICE': '6th Step Apprentice',
      '5TH_STEP_APPRENTICE': '5th Step Apprentice',
      '4TH_STEP_APPRENTICE': '4th Step Apprentice',
      '3RD_STEP_APPRENTICE': '3rd Step Apprentice',
      '2ND_STEP_APPRENTICE': '2nd Step Apprentice',
      '1ST_STEP_APPRENTICE': '1st Step Apprentice',
      HEAVY_EQUIPMENT_OPERATOR: 'Heavy Equipment Operator',
      MATERIAL_MAN: 'Material Man',
      EQ_OPER_FIELD_MECH_A: 'Eq Oper/Field Mech A',
      DRIVER_GROUNDMAN_CDL_A: 'Driver Groundman (CDL A)',
      FIELD_MECHANIC: 'Field Mechanic',
      GROUNDMAN: 'Groundman',
      FLAGMAN: 'Flagman',
      SAFETY: 'Safety',
      ADMINISTRATION: 'Administration',
    },
  },

  /* Pages */
  loginPage: {
    title: 'Welcome to Storm360',
    subTitle: 'To access your account, please Sign In.',
    email: 'Email',
    password: 'Password',
    forgotPassword: 'Forgot password?',
    buttonTitle: 'Sign in',
    dontHaveAccount: "Don't have an account?",
    linkCreateAccount: 'Create Account',
    error: 'Incorrect email or password. Please try again.',
    termsAndConditions: 'Terms & Conditions',
    agreement: 'I agree to',
  },

  createAccountPage: {
    title: 'Create Account',
    haveAccount: 'Already Have An Account?',
    role: 'Select Your Role',
    email: 'Email Address',
    password: 'Password',
    confirmPassword: 'Confirm Password',
    agreeStatement: 'By clicking Create Account, I agree to Storm360',
    termsAndConditions: 'User Terms & Conditions',
    buttonTitle: 'Create Account',
    privacyPolicy: 'Privacy Policy',
    completeYourProfile: 'Complete Your Profile',
    personalDetails: 'Personal details',
    ppeSizes: 'PPE Sizes',
    emailError: 'There is already an account associated with this email\naddress. If this is your account, please log in or reset\nyour password.',
  },

  verifyEmailPage: {
    title: 'Verify Your Email',
    verificationCode: 'We sent a 6-Digit Verification code to',
    enterCode: 'To continue, please enter the code here',
    incorrectCode: 'Incorrect verification code',
    didntRecieve: "Didn't receive a code?",
    resendCode: 'Resend code',
    otpError: 'The OTP code is incomplete. Please enter all 6 digits.',
    emailResent: 'Email has been resent',
  },

  waitingPage: {
    accountCreated: 'Your Account Is Created And',
    waitingApproval: 'Waiting For Approval',
    goToMyProfile: 'Go To My Profile',
  },

  emailVerifiedPage: {
    title: 'Email Is Verified',
    welcome: 'Welcome to Storm360!',
    description: 'We need to gather a few more details to get you ready for Storm...',
    buttonText: 'Continue With CLEAR',
  },

  createAccountWaitingPage: {
    awaitingDataVerification: 'Awaiting Data Verification',
    subtitle: 'Hang tight! We’re directing you back to Storm360',
  },

  confirmationPage: {
    title: 'Welcome to the Crew 🥳',
    identityVerified: 'Your identity has been verified',
    goOnStorm: 'To go on Storm, please complete your Profile',
    completeMyProfile: 'Complete My Profile',
    later: "I'll do it later",
  },

  rbacPage: {
    roles: 'Roles',
    attributes: 'Attributes',
    permissions: 'Permissions',
  },

  resetPasswordPage: {
    title: 'Forgot Your Password?',
    subTitle: 'Please choose how you want to reset your password',
    email: 'Via Email',
    phone: 'Via Phone',
    backTo: 'Back to',
    linkLogin: 'Sign in',
  },

  resetPasswordViaEmail: {
    title: 'Reset Password Via Email',
    subTitle: 'Enter your email address below and we’ll send you a reset link.',
    email: 'Email Address',
    request: 'Request Reset Link',
    notRegisteredEmailError: 'The email address entered is not registered in our system.',
    invalidEmailError: 'The email address entered is not valid.',
  },

  unsavedChangesPopup: {
    unsavedChanges: 'Unsaved Changes',
    changesLost: 'If you leave page, any changes you have made will be lost',
    stayOnPage: 'Stay On Page',
    leavePage: 'Leave Page',
  },

  actionForbiddenPopup: {
    actionIsForbidden: 'Action Is Forbidden',
    notAllowed: 'you are not allowed to',
    makeChanges: 'make any changes.',
  },

  actionsForbiddenSnackbar: {
    actionsForbidden: 'Actions Are Forbidden',
    rosterClosed: 'This Roster has been closed or transferred.',
    noActions: 'No actions allowed.',
  },

  detachPopup: {
    aboutDelete: 'You are about to delete',
    fromThisRoster: 'from this roster',
    yesDelete: 'Yes, Delete',
  },

  clearPopup: {
    title: 'Welcome to Storm360.\nGet Ready For\nIdentity Verification',
    verifyIdentity:
      "Storm360 will verify your identity using CLEAR. To complete the process, you'll need to " +
      '<strong>take a selfie</strong>, so please prepare yourself for this step.',
    haveDocuments:
      "Additionally, make sure to have either your <strong>Government-issued ID</strong> or <strong>Driver's License</strong> on " +
      'hand, as one of these documents will be required for verification.',
    note: "<i><strong>Note:</strong> CDL (Commercial Driver's License) is not accepted for this verification purpose.</i>",
    importantMessage: 'Important!\nAll information and documentation must be current for your profile to be verified.',
    continue: 'Continue with CLEAR',
    later: "I'll do it later",
    logOut: 'Log Out',
  },

  newPasswordPage: {
    title: 'Enter New Password',
    send: 'Set New Password',
  },

  expirationPage: {
    title: 'Reset Password Link Has Expired',
    getNewLink: 'Get a new link by trying',
    forgotPassword: 'forgot password',
    again: 'again.',
    return: 'Return To Sign In',
  },

  checkEmailPage: {
    title: 'Check Your Email',
    subTitle: 'We sent a password reset link to ',
    subTitleInfo: 'If the email address provided is registered in our system, you can expect to receive a password reset link shortly.',
    didntReceive: 'Didn’t receive the email? ',
    resend: 'Resend',
  },

  profilePage: {
    title: 'My Profile',
    successMessage: 'Your data is saved',
    id: 'ID',
    ppe: 'PPE',
    account: 'Account',
    cdlExpirationDate: 'CDL Expiration Date',
    unionAndClassification: 'Union & Class',
    emergencyContact: 'Emergency Contact',
    emailAndPassword: 'Email & Password',
    firstName: 'First Name',
    lastName: 'Last Name',
    fullName: 'Full Name',
    legalFirstName: 'Legal First Name',
    middleName: 'Middle Name',
    dateOfBirth: 'Date Of Birth',
    legalLastName: 'Legal Last Name',
    gender: 'Gender',
    phone: 'Phone Number',
    role: 'Selected Role',
    roles: 'Roles',
    s360Roles: 'Storm360 Role(s)',
    address: 'Address',
    streetAddress: 'Street Address',
    streetAddress2: 'Street Address 2',
    country: 'Country',
    stateRegion: 'State/Region',
    state: 'State',
    idFront: 'ID (front)',
    idBack: 'ID (back)',
    zipcode: 'Zip/Postal Code',
    city: 'City',
    email: 'Email Address',
    employee: 'Employee',
    preferredName: 'Preferred Name',
    medicalExpirationData: 'Medical Expiration Date',
    medicalCard: 'DOT Medical Card',
    classification: 'Classification',
    ssn: 'SSN #',
    medicalExpiration: 'Medical Expiration',
    rubberSleeve: 'Rubber Sleeve',
    workVest: 'Work Vest',
    workGlove: 'Work Glove',
    rubberGlove: 'Rubber Glove',
    localUnion: 'Local Union',
    unionTicket: 'Union Ticket',
    license: 'License #',
    driverLicenseState: 'State',
    driverLicense: 'Driver License #',
    driverLicenseClass: 'Class',
    driverLicenseExpiration: 'Driver License Expiration',
    driverLicenseFront: "Driver's License (front)",
    driverLicenseBack: "Driver's License (back)",
    cdlEndorsements: 'CDL Endorsements',
    cdlRestrictions: 'CDL Restrictions',
    relationship: 'Relationship',
    passport: 'Passport #',
    class: 'class',
    passportExpiration: 'Passport Expiration',
    expirationDate: 'Expiration Date',
    union: 'Union',
    generalInfoTab: 'General Info',
    attachedFilesTab: 'Attached Files',
    noAttachments: 'No Attachments yet',
    comment: 'Comment',
    newPasswordCreated: 'New password has been successfully created.',
    cantChangePassword: "Password can't be changed",
    passwordDontMatch: 'Can not change password. The password does not match',
    thankYouForJoining:
      'Thank you for joining us! Your account has not been activated. Please fill out all the required profile information in this section. ',
    gotIt: 'Ok, Got it',
    willReviewAccount: 'Vision Utility will then review your profile and activate your account.',
    needHelp: 'Need help? Check out the',
    guides: "'Guides'",
    changePassword: 'Change Password',
    leaveComment: 'Leave a comment',
    characterLimit: 'Character limit exceeded. Your input must be 512 characters or less.',
    commentNotEmpty: 'Comment must not be empty.',
    sendComment: 'Send Comment',
    instructions: 'tab where you can find step-by-step instructions on completing your profile.',
    verify: 'Verify',
    reject: 'Reject',
    emailVerified: 'Email verified',
    onboardingDetails: 'Onboarding details',
    clearFailed: 'CLEAR failed due to:',
    updateMyInfo: 'Update My Info',
    accountVerified: 'Account information is verified by CLEAR.',
    updateInClear: '\nTo update your information, please go to the',
    clearApp: 'CLEAR app',
    profilePicture: 'Profile Picture',
    dragPhoto: 'Drag to change the position of the photo',
    zoom: 'Zoom',
    imgSizeError: 'Your image is larger than 4 MB. Please choose a smaller image.',
    imgDescription: 'JPG or PNG. Max size 4 MB',
    changePhoto: 'Change Photo',
    deletePhoto: 'Delete Photo',
    uploadPhoto: 'Upload Photo',
    save: 'Save',
    cancel: 'Cancel',
    yesDelete: 'Yes, Delete',
    deleteImgTitle: 'Delete Profile picture',
    deleteImgSubTitle: 'Are you sure you want to delete your picture profile?',
    clearSuccess: 'CLEAR success',
    clearNotStarted: 'CLEAR not started',
    clearInProgress: 'CLEAR verification is in progress',
  },

  broadcastPage: {
    allJobs: 'All Jobs',
    invited: 'Invited',
    myJobs: 'My Jobs',
    noJobs: 'No jobs yet',
    checkLater: 'There are no jobs yet. Check it later',
    upcoming: 'Upcoming',
    active: 'Active',
    past: 'Past',
    no: 'No',
    jobsYet: 'jobs yet',
    thereAreNo: 'There are no',
    checkIt: 'jobs yet. Check it later',
    ibewPay: 'IBEW Pay',
    declined: 'Declined',
    onRoster: 'On Roster',
  },

  eventsPage: {
    events: 'Events',
    id: 'Code',
    name: 'Display Name',
    nameDescription: 'Please enter the name of the storm for your internal use.',
    ownedBy: 'Owned By',
    createdBy: 'Created By',
    updatedBy: 'Updated By',
    utility: 'Utility',
    utilityContractor: 'Utility Contractor',
    workLocation: 'Work Location',
    assignedTo: 'Assigned To',
    ibewPay: 'IBEW Pay',
    benefitsPay: 'Benefits Pay',
    travelReimbursement: 'Travel Reimb.',
    lodgingProvided: 'Lodging',
    meals: 'Meals',
    receipts: 'Receipts',
    fteCount: 'Req. FTEs',
    fleetCount: 'Req. Fleet',
    yardFK: 'Report to yard',
    expectedDate: 'Report Date',
    time: 'Report Time',
    estimatedDuration: 'Estimated Duration',
    dailyHours: 'Hours per Day',
    status: 'Status',
    menu: 'Actions',
    requested: 'Requested',
    disposition: 'Disposition',
    fte: 'FTE',
    eqpt: 'Eqpt',
    createEvent: 'Create Event',
    noEventsTitle: 'No Events',
    noEventsDescription: 'There are no Events created',
    receiptsRequired: 'Receipts required',
    fuel: 'Fuel',
    travel: 'Travel',
    lodging: 'Lodging',
    eventCode: 'Event code',
    goToGeneralTabs: 'Please go to General tab and select Utility first.',
    deleteIncident: 'Delete Event?',
    aboutToDeleteIncident: 'You are about to delete the event',
    yesDelete: 'Yes, Delete',
    general: 'General',
    compensation: 'Compensation',
    requirements: 'Requirements',
    reportTo: 'Report To',
    update: 'Update',
    saveAsDraft: 'Save as draft',
    activate: 'Activate',
    incidentCantDelete: "Incident can't be deleted",
    relatedRosters: 'The incident with related rosters can&apos;t be deleted.',
    askGF: 'Ask General Foreman to delete Rosters first.',
    code: 'This code is generated automatically by the system',
  },

  eventPage: {
    menu: 'Actions',
    sendInvite: 'Send Invite',
    addToRoster: 'Add To Roster',
    reject: 'Reject',
    decline: 'Decline',
    invite: 'Invite',
    linemen: 'Linemen',
    transfer: 'Transfer To Roster...',
    addLinemen: 'Add Linemen',
    addVehicle: 'Add Vehicle',
    addSupport: 'Add Support',
    timesheet: 'Timesheet',
    remove: 'Remove',
    cancel: 'Cancel',
    confirm: 'Confirm',
    sendInviteInfo: 'People who receive invitations will be placed on the Stand-by list.',
    selectYardLocation: 'Select Yard location(s).',
    versionHistory: 'Version history',
    currentModifiedVersion: 'Current modified version',
    inviteStatus: 'Invite status',
    yes: 'Yes',
    no: 'No',
    pending: 'Pending',
    sent: 'Sent',
    declinePeopleTitle: 'Decline people',
    declinePeopleSubTitle: 'Users who were invited to those Yards will be declined.',
    declined: 'Declined',
    noRosters: 'No Rosters created',
    allRosters: 'All Rosters',
    delete: 'Delete',
    transferTo: 'Transfer to...',
    changeStatusTo: 'Change status to:',
    byAddingPeople: 'By adding People to the Roster you confirm their participation.',
    saveMyResponse: 'Save my response and never ask again.',
    id: 'ID',
    someChangesInRoster: 'You made some changes in this roster.',
    doNotForget: 'Do not forget to send them for approval.',
    businessMangerDidNotApprove: 'Business manager did not approve it.',
    deleteRoster: 'Delete roster?',
    toDeleteRoster: 'You are about to delete roster',
    yesDelete: 'Yes, Delete',
    eventDelete: 'Event code',
    workLocation: 'work location',
    reportToYard: 'Report To Yard',
    utility: 'Utility',
    utilityContractor: 'Utility Contractor',
    showLess: 'Show less',
    showMore: 'Show more',
    estDuration: 'est. duration',
    dailyDuration: 'daily duration',
    expectedStartDate: 'Expected Start Date',
    reportTime: 'report time',
    dailyHours: 'daily hours',
    time: 'Time',
    receiptsRequired: 'receipts required',
    generalInfo: 'General info',
    startDay: 'start day',
    startTime: 'start time',
    resources: 'Resources',
    myRosters: 'My Rosters',
    noAvailablePeople: 'No Available People',
    allPeopleAreBusy: 'All people are busy right now.',
    tryAgainLater: 'Try again later.',
    noAvailableFleet: 'No Available Fleet',
    fleetIsBusy: 'The fleet is busy right now or is under',
    maintanance: 'maintenance. Check it later.',
    rosters: 'Rosters',
    createRoster: '+ Create Roster',
    noRostersCreated: 'No Rosters created',
    correspondingButton: 'Click the corresponding button to create',
    firstRoster: 'first Roster',
    inviteSent: 'Invite has been sent successfully.',
    somePeopleNotAdded: 'Some people were not added to the roster',
    goBack: 'go back to the list',
    addedToRoster: 'Added to roster ID',
    transferRoster: 'Transfer Roster',
    next: 'Next',
    ok: 'Ok, Got it',
    noEventsTitle: 'No Active Events',
    noEvents: 'There are currently no active events available for roster transfer.\nPlease check back later or create a new event to proceed.',
  },

  approvalsPage: {
    noApprovals: 'No Approvals',
  },

  fleetManagementPage: {
    companyTag: 'Tag',
    type: 'Type',
    yard: 'Yard',
    administrativeFlag: 'Admin',
    maintenanceFlag: 'Maint',
    repairFlag: 'Repair',
    availability: 'Avail',
    year: 'Year',
    make: 'Make',
    model: 'Model',
    color: 'Color',
    vin: 'VIN',
    gvwr: 'GVWR',
    ownerStatus: 'Owner Status',
    ownerField: 'Owner',
    insuranceStatus: 'Insurance Status',
    insuranceExpirationDate: 'Insurance Expiration Date',
    registrationType: 'Registration Type',
    registrationState: 'Registration State',
    registrationPlate: 'Registration Plate',
    registrationStatus: 'Registration Status',
    registrationExpirationDate: 'Registration Expiration Date',
    fmcsaStatus: 'FMCSA Status',
    fmcsaExpirationDate: 'FMCSA Expiration Date',
    dielectricStatus: 'DiElectric Status',
    dielectricExpirationDate: 'DiElectric Expiration Date',
    ansiStatus: 'ANSI Status',
    ansiExpirationDate: 'ANSI Expiration Date',
    iftaTagStatus: 'IFTA Tag Status',
    iftaTagExpirationDate: 'IFTA Tag Expiration Date',
    iftaTagNumber: 'IFTA Tag #',
    ezPassNumber: 'EZPass #',
    administrativeNotes: 'Administrative Notes',
    nextServiceDate: 'Next Service Date',
    maintenanceNotes: 'Maintenance Notes',
    fuelCard: 'Fuel Card #',
    gpsId: 'GPS ID #',
    assignedTo: 'Assigned To',
    operationalNotes: 'Operational Notes',
  },

  fleetDefaultColumns: {
    yardFK: 'Yard',
    yardFK_desc: 'Yard where fleet is located',
  },

  fleetReadinessPage: {
    buckets: 'Buckets Info',
    buckets_desc: 'Buckets Readiness Information',
    diggers: 'Diggers Info',
    diggers_desc: 'Diggers Readiness Information',
    pickups: 'Pickups Info',
    pickups_desc: 'Pickups Readiness Information',
    trailers: 'Trailers Info',
    trailers_desc: 'Trailers Readiness Information',
    other: 'Other Info',
    other_desc: 'Other Readiness Information',
    operational: 'Opl.',
    operational_desc: 'Fully Operational Count',
    total: 'Tot.',
    total_desc: 'Total Count',
    readiness: 'Rdns.',
    readiness_desc: 'Readiness %',
  },

  fleetObjectivesPage: {
    buckets: 'Buckets Info',
    buckets_desc: 'Buckets Objectives Information',
    diggers: 'Diggers Info',
    diggers_desc: 'Diggers Objectives Information',
    pickups: 'Pickups Info',
    pickups_desc: 'Pickups Objectives Information',
    trailers: 'Trailers Info',
    trailers_desc: 'Trailers Objectives Information',
    other: 'Other Info',
    other_desc: 'Other Objectives Information',
    target: 'Tgt.',
    target_desc: 'Target Objective %',
    readiness: 'Rdns.',
    readiness_desc: 'Actual Readiness %',
  },

  fleetAdminPage: {
    dielectric: 'Dielectric Info',
    ansi: 'ANSI Info',
    fmcsa: 'FMCSA Info',
    reg: 'Registration Info',
    ifta: 'IFTA Info',
    dielectric_desc: 'Dielectric Compliance Information',
    ansi_desc: 'ANSI Compliance Information',
    fmcsa_desc: 'FMCSA Compliance Information',
    reg_desc: 'Registration Compliance Information',
    ifta_desc: 'IFTA Compliance Information',
    equipmentCount: 'Total',
    equipmentCount_desc: 'Equipment Count at Yard',
    compliant: 'Cmpl.',
    compliant_desc: 'Equipment in Compliance',
    due: 'Due',
    due_desc: 'Equipment Compliance Due within 30 Days',
    expired: 'Exp.',
    expired_desc: 'Equipment Compliance Expired',
    aboutToDeleteVehicle: 'You are about to delete the vehicle',
    deleteVehicle: 'Do you really want to delete the vehicle?',
  },

  rosterPeoplePage: {
    alert: 'Alert',
    status: 'Status',
    crewLead: 'Lead',
  },

  rosterFleetPage: {
    alert: 'Alert',
    crewId: 'Crew ID',
    availableToAdd: 'Available Vehicles',
    addedToRoster: 'Roster',
    noAvailableFleet: 'No available Fleet.',
    noAddedFleet: 'No fleet added to yet.',
    yard: 'Yard',
    administrativeFlag: 'Administrative Flag',
    companyTag: 'Company Tag',
    ownerStatus: 'Owner Status',
    owner: 'Owner',
    insuranceStatus: 'Insurance Status',
    insuranceExpirationDate: 'Insurance Expiration Date',
    registrationType: 'Registration Type',
    registrationState: 'Registration State',
    registrationPlate: 'Registration Plate',
    registrationStatus: 'Registration Status',
    registrationExpirationDate: 'Registration Expiration Date',
    fmcsaStatus: 'FMCSA Status',
    fmcsaExpirationDate: 'FMCSA Expiration Date',
    diElectricStatus: 'DiElectric Status',
    diElectricExpirationDate: 'DiElectric Expiration Date',
    ansiStatus: 'ANSI Status',
    ansiExpirationDate: 'ANSI Expiration Date',
    iftaTagStatus: 'IFTA Tag Status',
    iftaTagExpirationDate: 'IFTA Tag Expiration Date',
    iftaTagNumber: 'IFTA Tag #',
    ezpassNumber: 'EZPass #',
    administrativeNotes: 'Administrative Notes',
    vehicleType: 'Vehicle Type',
    make: 'Make',
    model: 'Model',
    year: 'Year',
    color: 'Color',
    vin: 'VIN',
    gvwr: 'GVWR',
    maintenanceFlag: 'Maintenance Flag',
    repairNeeded: 'Repair Needed',
    yardAssignment: 'Yard Assignment',
    nextServiceDate: 'Next Service Date',
    maintenanceNotes: 'Maintenance Notes',
    availability: 'Availability',
    fuelCardNumber: 'Fuel Card #',
    gpsIdNumber: 'GPS ID #',
    assignedTo: 'Assigned To',
    operationalNotes: 'Operational Notes',
    fleetManagementInventory: 'Fleet Management Inventory',
    noFleet: 'No Fleet',
    noVehicles: 'There are no vehicles created',
    createVehicle: 'Create Vehicle',
    general: 'General',
    editVehicle: 'Edit Vehicle',
    administrative: 'Administrative',
    maintenance: 'Maintenance',
    operational: 'Operational',
  },

  fleetDashboardPage: {
    objectivesDashboard: 'Objectives Dashboard',
    readinessDashboard: 'Readiness Dashboard',
    adminDashboard: 'Admin Dashboard',
    noReports: 'No Reports',
    noReportsCreated: 'There are no reports created',
  },

  fleetReportsPage: {
    maintenance: 'Maintenance',
    administrative: 'Administrative',
    breakdownByVehicle: 'Breakdown by Vehicle',
    expire: 'Expire',
    expired: 'Expired',
  },

  rosterStandByPage: {
    availableToAdd: 'Available Linemen',
    availableToAddSupport: 'Available Support',
    emptyHeader: 'List Is Empty Yet',
    emptySubheader: 'Go to Resources tab to invite People',
  },

  rosterFavoritesPage: {
    availableToAdd: 'Available Favorites',
    emptyHeader: 'List Is Empty Yet',
    emptySubheader: 'List is empty yet. \nGo to User Directory tab to add \nPeople to Favorites',
  },

  myRostersPage: {
    id: 'ID',
    name: 'Name',
    yardFK: 'Report To Yard',
    startDate: 'Start Date',
    startTime: 'Start Time',
    eventName: 'Event Name',
    status: 'Status',
  },

  rostersTable: {
    id: 'ID',
    name: 'Name',
    createdBy: 'Created By',
    ownedBy: 'Owned By',
    yardFK: 'Report To Yard',
    startDate: 'Start Date',
    startTime: 'Start Time',
    eventName: 'Event Name',
    status: 'Status',
  },

  rosterPage: {
    rejected: 'Rejected',
    viewing: 'Viewing',
    splitRoster: 'Split roster',
    approve: 'Approve',
    reject: 'Reject',
    editRoster: 'Edit Roster',
    forApproval: 'For Approval',
    rejectRoster: 'Reject Roster?',
    aboutToReject: 'You are about to reject',
    characterLimit: 'Character limit exceeded. Your input must be 512 characters or less.',
    rosterRejected: 'Roster Was Rejected',
    rosterReApproval: 'Roster Requires Re Approval',
    save: 'Save',
    cancel: 'Cancel',
    goToRoster: 'Go To Roster',
    fteEmptyTitle: 'No People added',
    fteEmptySubtitle: 'Add people from Available Linemen \nor Resources',
    equipmentEmptyTitle: 'No equipment added',
    equipmentEmptySubtitle: 'Add equipment from Available Vehicles',
    vehiclesEmptyAvailable: 'No available vehicles.',
    vehiclesEmptyAdded: 'No vehicles added yet.',
    linemenEmptyAvailable: 'List is empty yet. \nGo to Resources tab to invite People',
    linemenEmptyAdded: 'List is empty yet. \nAdd people from Available Linemen \nor Resources',
    supportEmptyAvailable: 'List is empty yet.',
    supportEmptyAdded: 'List is empty yet. \nAdd people from Available Support \nor Resources',
    revertChanges: 'Revert Changes',
    restoreVersion: 'Restore version',
    restore: 'Restore',
    current: 'Current',
    restoreVersionPopupSub: 'Are you sure you want to restore this Roster to Version',
    restoreVersionPopupSub2: 'All current changes will be lost.',
    downloadUtilityCSV: 'Download Utility Roster CSV',
    listIsEmpty: 'List is empty yet.',
    goTo: 'Go To',
    tabToInvote: 'tab to invite people',
    addPeople: 'Add people from Available Linemen',
    or: 'or',
    rosterId: 'roster id',
    displayName: 'Display name',
    startTime: 'Start Time',
    status: 'Status',
    reportToYard: 'Report To Yard',
    startDay: 'start day',
    reportTime: 'report time',
    noFleet: 'No fleet added yet.',
    fullName: 'Full Name',
    phone: 'Phone',
    email: 'Email',
    tabToAdd: 'tab to add.',
    dataAvailable: 'The data will become available once you add people from the',
    setClassifications: 'tab and set their classifications in the roster.',
    companyContact: 'company contact',
    crewContact: 'crew contact',
    alternateContact: 'Alternate Contact',
    noData: 'No data',
    generalInfo: 'General Info',
    yardFK: 'Yard Assignment',
    reApproval: 'Changes require re approval',
    yesReject: 'Yes, Reject',
    companyTag: 'Company tag:',
    noFleetGoTo: 'No fleet added. Go to',
    tab: 'tab',
    fleetToRoster: 'to add fleet to the roster.',
    noRostersCreated: 'No Rosters Created',
    goToEvents: 'Go to events and click',
    correspondingButton: 'corresponding button to create Roster',
  },

  rosterOverviewPage: {
    overview: 'Overview',
    myCrew: 'My Crew',
    myEqpt: 'My Eqpt',
    generalInfo: 'General Info',
    contacts: 'Contacts',
    reportDay: 'Report Day',
    reportTime: 'Report Time',
    reportToYard: 'Report To Yard',
    ftes: 'FTES',
    equipment: 'Equipment',
    companyContact: 'Company Contact',
    crewContact: 'Crew Contact',
    alternateContact: 'Alternate Contact',
    noData: 'No Data',
    archivedRoster: 'Viewing archived roster',
    fullName: 'Full Name',
    phone: 'Phone Number',
    unionClassification: 'Union Classification',
    unionClass: 'Union Class.',
    assignedVehicle: 'Assigned Vehicle',
    companyTag: 'Company Tag',
    assignedPeople: 'Assigned People',
    type: 'Type',
    assignedTo: 'Assigned To',
    crewId: 'Crew Id',
    myCrewEqpt: 'My Crew Equipment',
  },

  clearmeSomethingWentWrongPage: {
    title: 'Something Went Wrong',
    subTitle: 'Your information could not be verified.',
    buttonTitle: 'Try Again',
  },

  rosterOverviewHeader: {
    displayName: 'Display Name',
    status: 'Status',
    utility: 'Utility',
    utilityContractor: 'Utility Contractor',
    ibewPay: 'IBEW Pay',
    benefitsPay: 'Benefits Pay',
    travelReimbursement: 'Travel Reimbursement',
    lodgingProvided: 'Lodging Provided',
    meals: 'Meals',
    showMore: 'Show more',
    showLess: 'Show less',
  },

  incidentCannotClose: {
    cantClose: "Event can't be closed",
    cantCloseSubtitle: "The event with active Rosters can't be closed.",
    cantCloseAskSubtitle: 'Ask General Foreman to close Rosters first.',
    cantActivate: "Event can't be activated",
    cantActivateSubtitle: 'Check if all the required fields were entered during the Event creation.',
    buttonTitle: 'Ok, Got it',
  },

  declinePeople: {
    title: 'Decline People?',
    content: 'People will receive notification about declining',
    cancel: 'Cancel',
    decline: 'Yes, Decline',
  },

  usersPage: {
    userManagement: 'User Management',
    title: 'Users',
    noUsers: 'No Users Yet',
    thereNoUsers: 'There are no managed users',
  },

  splitRosterPage: {
    cancel: 'Cancel',
    previous: 'Previous',
    nextStep: 'Next Step',
    saveAndPublish: 'Save and publish',
    ftes: 'FTEs',
    equipment: 'Equipment',
    rosterDetails: 'Roster Details',
    newRoster: 'New Roster',
    noFtesToMove: 'No FTEs to Move',
    selectFTEsToMove: 'Select FTEs to Move',
    noVehiclesToMove: 'No Vehicles to Move',
    selectVehiclesToMove: 'Select Vehicles to Move',
    generalInfo: 'General Info',
    yardAssignment: 'Yard Assignment',
    startDay: 'Start Day',
    reportTime: 'Report Time',
    rosterOwner: 'Roster Owner',
    selectNewOwner: 'Select New Roster Owner',
    alternateContact: 'Alternate Contact',
    fullName: 'Full Name',
    phoneNumber: 'Phone Number',
    emailAddress: 'Email Address',
  },

  underConstructionPage: {
    title: 'Under Construction',
    subtitle: 'This page in under construction.',
    stayTuned: 'Stay tuned',
  },

  timesheetDialog: {
    title: 'Timesheet',
    subTitle: 'Set For All',
    startDate: 'Start Date',
    startTime: 'Start Time',
    endDate: 'End Date',
    endTime: 'End Time',
    apply: 'Apply',
    fullName: 'Full Name',
    classification: 'Classification',
    phone: 'Phone',
    crewId: 'Crew ID',
    assignFleet: 'Assign Fleet',
    companyTag: 'Company Tag',
    gender: 'Gender',
    lodging: 'Lodging',
    email: 'Email',
    state: 'State',
    foreman: 'Foreman',
    street: 'Street',
    city: 'City',
    zipcode: 'Zipcode',
    cancel: 'Cancel',
    yes: 'Yes',
    no: 'No',
    save: 'Save and Export CSV file',
  },

  jobDetailsDialog: {
    yes: 'Yes',
    no: 'No',
    acceptOffer: 'You accepted offer',
    rejectOffer: 'You rejected offer',
    goToRoster: 'Go to roster',
    rosterDetails: 'Roster Details',
    eventDetails: 'Event Details',
    confiremedBy: 'Confirmed by',
    reportToYard: 'Report to yard',
    time: 'Time',
    utility: 'Utility',
    agreement: `A user who is also a member of IBEW hereby affirms that such user shall abide by all IBEW rules, laws and regulations, 
      including the NECA/IBEW National Outside Emergency Response.`,
    workLocation: 'work location',
    ibewPay: 'ibew pay',
    benefitsPay: 'benefits pay',
    travelReimbursement: 'Travel Reimbursement',
    lodgingProvided: 'lodging provided',
    meals: 'meals',
    receiptsRequired: 'receipts required',
  },

  /* Switch Roles pop-up window */
  switchRoles: {
    title: 'Switch Roles',
    myProfile: 'My Profile',
    logout: 'Log Out',
  },

  error403Page: {
    title: '403',
    home: 'Go home',
    subTitle: 'Accessing the page you were trying to\nreach is forbidden',
  },

  error404Page: {
    title: '404',
    subTitle: 'The page you requested does not exist.',
    buttonTitle: 'Go Back',
  },

  errorConnectionPage: {
    title: 'Opps!..',
    subTitle: 'Something wrong with your connection. Please try again.',
    buttonTitle: 'retry',
  },

  fteStatuses: {
    accepted: 'Accepted',
    rejected: 'Rejected',
    pending: 'Pending',
    acceptedOnBehalf: 'Accepted on behalf',
    crewLead: 'Crew lead',
  },

  // TODO: the list should be extend with all neeed values
  options: {
    // Utility
    N_A: 'N/A',
    EVERSOURCE: 'Eversource',
    NATIONAL_GRID: 'National grid',
    // Eversource
    FOREMAN: 'Foreman',
    GENERAL_FOREMAN: 'General foreman',
    // National Grid
    HEAD_LINEMAN: 'Head lineman',
    JOURNEMAN_LINEMAN: 'Journeman lineman',
  },

  RubberSleeveSize: {
    SMALL: 'Small',
    MEDIUM: 'Regular',
    LARGE: 'Large',
    EXTRA_LARGE: 'XL',
  },

  editVehicle: {
    cancel: 'Cancel',
    update: 'Update',
    save: 'Save',
    create: 'Create',
  },

  guidePage: {
    title: 'How to Guides',
    editProfile: 'Edit Profile Info',
    broadcastNav: 'Broadcast Navigation',
    userManagement: 'User Management',
    eventCreation: 'Event Creation',
    addGFeditEvent: 'Add GF, Edit Event',
  },

  noResponsePage: {
    title: 'No Response',
    subTitle: 'CLEAR is not responding.',
    button: 'Try Again',
  },

  phoneInUsePage: {
    title: 'Opps!',
    subTitle: 'It looks like this phone number is already\n in our system.',
    button: 'Go To Home',
  },

  updateInfoPopup: {
    title: 'Update your info with CLEAR',
    subTitle1: `You're trying to update information that has been verified by Storm360's partner, CLEAR. 
      To make a change, download the CLEAR app and follow the instructions to log-in using a selfie.`,
    subTitle2: `Once you're signed in to CLEAR, navigate to settings to update your information.`,
  },
};

export default en;
