import { styled } from '@mui/material/styles';
import { P3 } from 'src/components';
import { themeColors } from 'src/theme';
import { UserStatus as UserStatusEnum } from 'src/shared/enums/userStatus.enum';

const StyledUserStatus = styled(P3)({
  padding: '0 10px',
  display: 'flex',
  alignItems: 'center',
  textTransform: 'lowercase',
  borderRadius: '4px',
});

export const userStatusNameByCode = {
  [UserStatusEnum.DEACTIVATED]: 'Pending',
  [UserStatusEnum.ACTIVE]: 'Not Active',
  [UserStatusEnum.BLOCKED]: 'Blocked',
  [UserStatusEnum.APPROVED]: 'Approved',
};

const colorByStatusCode = {
  [UserStatusEnum.DEACTIVATED]: themeColors.redLight,
  [UserStatusEnum.ACTIVE]: themeColors.lightYellow,
  [UserStatusEnum.BLOCKED]: themeColors.greenLight,
  [UserStatusEnum.APPROVED]: themeColors.blueLight,
};

type UserStatusProps = {
  status: UserStatusEnum;
};

const UserStatus = ({ status }: UserStatusProps) => {
  return <StyledUserStatus sx={{ backgroundColor: colorByStatusCode[status] }}>{userStatusNameByCode[status]}</StyledUserStatus>;
};

export default UserStatus;
