import { useEffect, useState } from 'react';
import { useNavigate, useParams, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Box, Grid, Tabs, Tab } from '@mui/material';
import { BoxProps } from '@mui/material/Box';
import { GridRenderCellParams } from '@mui/x-data-grid';

import { H2, Button, P1R, Icon, H1, P2, Notification, UsersTable, FleetTable, Toolbar, Label, Loader, SomethingWentWrongState } from 'src/components';
import { TableAdvancedMetaColumn } from 'src/components/Table/TableAdvanced';
import { usersColumnVisibilityModel } from 'src/components/UsersTable';
import { fleetColumnVisibilityModel } from 'src/components/FleetTable';
import RosterStatusComponent from 'src/components/RostersTable/RosterStatus';
import { setParams, formatIncidentId } from 'src/utils';
import { themeColors } from 'src/theme';

import { PATHS } from 'src/navigation';
import { loadUser } from 'src/redux/user/actions';
import { selectUser, selectUpdateSettingsSuccess } from 'src/redux/user/selectors';
import { loadLinemen, notifyUsers } from 'src/redux/users/actions';
import { selectNotifyUsersError, selectNotifyUsersSuccess, selectSupport, selectLinemen } from 'src/redux/users/selectors';
import { loadFilteredVehicles } from 'src/redux/vehicles/actions';
import { selectVehicles } from 'src/redux/vehicles/selectors';
import { loadIncident, incidentDeclineLinemen } from 'src/redux/incident/actions';
import {
  selectIncident,
  selectIncidentSuccess,
  selectIncidentDeclineLinemenSuccess,
  selectIncidentError,
  selectIncidentLoading,
} from 'src/redux/incident/selectors';
import { createRoster, deleteRoster, rosterAttachLinemen, rosterAttachVehicles, saveRoster } from 'src/redux/roster/actions';
import {
  selectRosterAttachingLinemen,
  selectRosterAttachingLinemenSuccess,
  selectRosterAttachingLinemenErrors,
  selectRosterAttachingVehicles,
  selectRosterAttachingVehiclesSuccess,
  selectRosterAttachingVehiclesError,
  selectCreatingRosterSuccess,
  selectDeletingRosterSuccess,
  selectSavingRosterSuccess,
  selectRosterTransferError,
} from 'src/redux/roster/selectors';
import { loadRosters } from 'src/redux/rosters/actions';
import { selectMyRosters, selectRosters, selectRostersError } from 'src/redux/rosters/selectors';
import { WorkerType, IncidentLinemen, RosterStatus, RosterType } from 'src/types';
import { PermissionEnum } from 'src/shared/enums/permission.enum';

import config from 'src/config';
import fleetPic from 'src/assets/fleet.svg';
import emptyLineman from 'src/assets/linemanEmpty.svg';
import linemanPic from 'src/assets/linemanGroup.svg';

import { DashboardWrapper } from '../wrappers';
import Dialog from '../RosterContainer/Dialog';
import CardRoster from './CardRoster';
import RosterSnackbar from './RosterSnackbar';
import DeletePopup from './DeletePopup';
import IncidentHeader from './IncidentHeader';
import AddToRosterDialog from './AddToRosterPopup';
import ActionForbiddenPopup from './ActionForbiddenPopup';
import LinemanNotAvailableDialog from './LinemanNotAvailablePopup';
import SendInvitePopup from './SendInvitePopup';
import DeclineLinemenPopup from './DeclineLinemenPopup';
import AllRostersTab from './IncidentTabs/AllRostersTab';
import ActionsAreForbiddenSnackbar from '../RosterContainer/ActionsAreForbiddenSnackbar';

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps & BoxProps) {
  const { children, value, index, ...other } = props;

  return (
    <Box
      role="tabpanel"
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      sx={{
        display: 'flex',
        flexDirection: 'column',
        flexGrow: 1,
        overflowY: 'hidden',
        ...(value !== index && { display: 'none' }),
      }}
      {...other}
    >
      {value === index && <Box sx={{ display: 'flex', flexDirection: 'column', flexGrow: 1, overflowY: 'hidden' }}>{children}</Box>}
    </Box>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

type ResourcesTab = 'linemen' | 'vehicles' | 'support';

type TabValueMapping = {
  [key: string]: number;
};

const tabValueMapping: TabValueMapping = {
  resources: 0,
  myRosters: 1,
  allRosters: 2,
};

function Incident() {
  const canHaveOwnRoster = config.can([PermissionEnum.ADD_NEW_ROSTER_AS_ASSIGNED_GF, PermissionEnum.ADD_NEW_ROSTER_IN_ANY_EVENT]);

  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const incident = useSelector(selectIncident);
  const isLoadIncidentSuccess = useSelector(selectIncidentSuccess);
  const isLoadIncidentError = useSelector(selectIncidentError);
  const isIncidentLoading = useSelector(selectIncidentLoading);
  const myRosters = useSelector(selectMyRosters);
  const rosters = useSelector(selectRosters);
  const user = useSelector(selectUser);
  const creatingRosterSuccess = useSelector(selectCreatingRosterSuccess);
  const deletingRosterSuccess = useSelector(selectDeletingRosterSuccess);
  const notifyUsersSuccess = useSelector(selectNotifyUsersSuccess);
  const rosterAttachingLinemen = useSelector(selectRosterAttachingLinemen);
  const rosterAttachingLinemenSuccess = useSelector(selectRosterAttachingLinemenSuccess);
  const rosterAttachingLinemenErrors = useSelector(selectRosterAttachingLinemenErrors) as Record<string, string>;
  const rosterAttachingVehicles = useSelector(selectRosterAttachingVehicles);
  const rosterAttachingVehiclesSuccess = useSelector(selectRosterAttachingVehiclesSuccess);
  const rosterAttachingVehiclesError = useSelector(selectRosterAttachingVehiclesError);
  const linemen = useSelector(selectLinemen);
  const notifyError = useSelector(selectNotifyUsersError);
  const vehicles = useSelector(selectVehicles);
  const supporters = useSelector(selectSupport);
  const savingRosterSuccess = useSelector(selectSavingRosterSuccess);
  const transferRosterError = useSelector(selectRosterTransferError);
  const updateSettingsSuccess = useSelector(selectUpdateSettingsSuccess);
  const incidentDeclineLinemenSuccess = useSelector(selectIncidentDeclineLinemenSuccess);
  const isRostersLoadingError = useSelector(selectRostersError);
  const [linemenSelected, setLinemenSelected] = useState<string[]>([]);
  const [vehiclesSelected, setVehiclesSelected] = useState([]);
  const [showSnackbar, setShowSnackbar] = useState(false);
  const [openCreateIncidentDialog, setOpenCreateIncidentDialog] = useState(false);
  const [selectedRoster, setSelectedRoster] = useState(0);
  const [rosterToDelete, setRosterToDelete] = useState<null | {
    id: number | undefined;
    name: string | undefined;
  }>(null);
  const { id = '0', eventTab, resourcesSubtab } = useParams();
  const activeEventTab = tabValueMapping[eventTab as keyof TabValueMapping];
  const activeResourcesTable =
    resourcesSubtab === 'linemen' || resourcesSubtab === 'vehicles' || resourcesSubtab === 'support' ? resourcesSubtab : 'linemen';
  const [activeResourcesSubtab, setActiveResourcesSubtab] = useState<ResourcesTab>(activeResourcesTable);
  const { state } = useLocation();
  const incidentId = parseInt(id, 10);
  const [openAddToRosterDialog, setOpenAddToRosterDialog] = useState(false);
  const [openSendInviteDialog, setOpenSendInviteDialog] = useState(false);
  const isAutoAddToRosterConfirmed = user.settings?.autoAddToRosterConfirmation;
  const [invitationSent, setInvitationSent] = useState(false);
  const [showDeclineLinemenDialog, setsShowDeclineLinemenDialog] = useState(false);
  const [attachResourceError, setAttachResourceError] = useState('');
  const [rosterToAttach, setRosterToAttach] = useState<RosterType | null>();
  const [supportersSelected, setSupportersSelected] = useState([]);
  const [invitedYardsIds, setInvitedYardsIds] = useState<number[]>([]);
  const [showActionsForbiddenSnackBar, setShowActionsForbiddenSnackBar] = useState(false);
  const [isRosterSplited, setIsRosterSplited] = useState(false);

  const canUseActions = config.can(PermissionEnum.SEND_INVITATION) || config.can('edit_roster');

  const handleTabsChange = (event: React.SyntheticEvent, newTab: number) => {
    if (activeEventTab !== newTab) {
      navigate(`/events/${id}/${Object.keys(tabValueMapping).find((key) => tabValueMapping[key] === newTab)}/${activeResourcesTable}`);
    }
  };

  const handleResourcesTableChange = (table: ResourcesTab) => {
    setActiveResourcesSubtab(table);
    navigate(`/events/${id}/${Object.keys(tabValueMapping).find((key) => tabValueMapping[key] === activeEventTab)}/${table}`);
  };

  const handleAttachLinemen = (rosterId: number) => {
    dispatch(
      rosterAttachLinemen.init({
        id: rosterId,
        linemen: linemenSelected,
        type: WorkerType.FTE,
      }),
    );
  };
  const handleAttachSupport = (rosterId: number) => {
    dispatch(
      rosterAttachLinemen.init({
        id: rosterId,
        linemen: supportersSelected,
        type: WorkerType.SUPPORT,
      }),
    );
  };

  const handleAttachVehicles = (rosterId: number) => {
    dispatch(
      rosterAttachVehicles.init({
        id: rosterId,
        vehicles: vehiclesSelected.map((id: number) => ({ id })),
      }),
    );
  };

  const handleDeleteRoster = () => {
    if (rosterToDelete?.id) {
      dispatch(deleteRoster.init({ id: rosterToDelete?.id }));
    }
  };

  const handleAttach = (rosterToAttach?: RosterType | null) => {
    // currently we are adding resourses to users default roster
    if (rosterToAttach && rosterToAttach.id) {
      if (rosterToAttach.status !== RosterStatus.PENDING_APPROVAL && rosterToAttach.status !== RosterStatus.CLOSED) {
        setSelectedRoster(rosterToAttach.id);
        if (activeResourcesSubtab === 'linemen') {
          handleAttachLinemen(rosterToAttach.id);
        } else if (activeResourcesSubtab === 'support') {
          handleAttachSupport(rosterToAttach.id);
        } else {
          handleAttachVehicles(rosterToAttach.id);
        }
      } else {
        const rosterStatus = {
          [RosterStatus.PENDING_APPROVAL]: t('rosterStatuses.PENDING_APPROVAL'),
          [RosterStatus.CLOSED]: t('rosterStatuses.CLOSED'),
        };
        setAttachResourceError(`While Roster is in ${rosterStatus[rosterToAttach.status]} status `);
      }
    }
  };

  const handleAddToRosterAction = (roster: RosterType) => {
    if (isAutoAddToRosterConfirmed || activeResourcesSubtab === 'vehicles') {
      setRosterToAttach(roster);
      handleAttach(roster);
    } else {
      setRosterToAttach(roster);
      setOpenAddToRosterDialog(true);
    }
  };

  const handleAddToRosterConfirm = () => {
    handleAttach(rosterToAttach);
    setOpenAddToRosterDialog(false);
  };

  const handleSendOffer = () => {
    setOpenSendInviteDialog(true);
  };

  const handleSendOfferConfirm = (yard: number[]) => {
    dispatch(notifyUsers.init({ incidentId, linemanUuids: linemenSelected, yardIds: yard }));
    dispatch(loadLinemen.init({ incidentId }));
  };

  const handleGoToRoster = () => {
    setShowSnackbar(false);
    navigate(setParams(PATHS.ROSTER_PAGE, { id: `${selectedRoster}` }));
  };

  const handleDeclineLinemen = (yardIds: number[]) => {
    dispatch(incidentDeclineLinemen.init({ id: incidentId, uuids: linemenSelected, yards: yardIds }));
  };

  const handleDeclineAction = () => {
    const invitedYardsIds = linemen
      .filter((lineman) => linemenSelected.includes(lineman.uuid))
      .reduce((acc: number[], item) => {
        let itemYards: number[] = [];
        if (item.incidentLinemen.length) {
          itemYards = item.incidentLinemen.map((i) => i.yardId);
        }
        return [...acc, ...itemYards];
      }, []);
    // TODO: update config compilerOptions
    // Type 'Set<number>' can only be iterated through when using the '--downlevelIteration' flag or with a '--target' of 'es2015' or higher.
    // @ts-ignore
    const uniqueYardIds = [...new Set(invitedYardsIds)];
    setInvitedYardsIds(uniqueYardIds);
    setsShowDeclineLinemenDialog(true);
  };

  const createRosterItem = (roster: RosterType) => ({
    title: (
      <Box sx={{ width: '430px', display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
        <Box>
          {t('eventPage.addToRoster')} {`"${roster?.name}"`}
        </Box>
        <RosterStatusComponent status={roster?.status} />
      </Box>
    ),
    onClick: () => handleAddToRosterAction(roster),
    active: roster?.status !== RosterStatus.PENDING_APPROVAL,
  });

  const resetAttachingLinemen = () => {
    dispatch(rosterAttachLinemen.reset());
    dispatch(loadLinemen.init({ incidentId }));
  };

  let rostersList: Array<{ title: React.ReactNode; onClick: () => void; active: boolean }> = [];
  rostersList = rosters.filter((roster) => config.can('edit_roster', { user, roster })).map(createRosterItem);

  const getLinemanActions = () => {
    const actions = [];

    if (config.can(PermissionEnum.SEND_INVITATION)) {
      actions.push({
        title: t('eventPage.sendInvite'),
        onClick: handleSendOffer,
        active: true,
      });
    }

    if (config.can('edit_roster')) {
      actions.push(
        {
          title: <P1R sx={{ color: themeColors.red }}>{t('eventPage.decline')}</P1R>,
          onClick: handleDeclineAction,
          active: true,
        },
        ...rostersList,
      );
    }

    return actions;
  };

  const getFleetActions = () => {
    const actions = [];
    config.can('edit_roster') && actions.push(...rostersList);

    return actions;
  };

  useEffect(() => {
    dispatch(loadIncident.init({ id: incidentId }));
    dispatch(loadRosters.init({ incidentId }));
    dispatch(loadLinemen.init({ incidentId }));
    dispatch(
      loadFilteredVehicles.init({
        // assignedUuid: user.uuid,
        administrativeFlag: false,
        maintenanceFlag: false,
        availability: true,
      }),
    );
  }, [dispatch, user.uuid, incidentId]);

  useEffect(() => {
    if (savingRosterSuccess) {
      dispatch(loadRosters.init({ incidentId }));
      dispatch(saveRoster.reset());
    }
  }, [dispatch, incidentId, savingRosterSuccess]);

  useEffect(() => {
    if (creatingRosterSuccess || deletingRosterSuccess) {
      dispatch(loadRosters.init({ incidentId }));
      dispatch(createRoster.reset());
      dispatch(deleteRoster.reset());
    }
  }, [creatingRosterSuccess, deletingRosterSuccess, dispatch, incidentId]);

  useEffect(() => {
    if (isLoadIncidentSuccess) {
      dispatch(loadIncident.reset());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoadIncidentSuccess]);

  useEffect(() => {
    if (rosterAttachingLinemenSuccess) {
      dispatch(loadLinemen.init({ incidentId }));
      setShowSnackbar(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rosterAttachingLinemenSuccess, dispatch]);

  useEffect(() => {
    if (notifyUsersSuccess) {
      setLinemenSelected([]);
      dispatch(loadLinemen.init({ incidentId }));
      setInvitationSent(true);
    }
  }, [dispatch, notifyUsersSuccess, incidentId]);

  useEffect(() => {
    if (rosterAttachingVehiclesSuccess) {
      dispatch(
        loadFilteredVehicles.init({
          //  assignedUuid: user.uuid,
          administrativeFlag: false,
          maintenanceFlag: false,
          availability: true,
        }),
      );
      setShowSnackbar(true);
      dispatch(rosterAttachVehicles.reset());
    }
  }, [rosterAttachingVehiclesSuccess, rosterAttachingVehiclesError, dispatch]);

  useEffect(() => {
    if (updateSettingsSuccess) {
      dispatch(loadUser.init());
    }
  }, [updateSettingsSuccess]);

  useEffect(() => {
    if (state?.tab === 'all-rosters') {
      handleTabsChange(null as unknown as React.SyntheticEvent, 2);
    }
    if (state?.splitRoster) {
      setIsRosterSplited(true);
    }
  }, [state]);

  useEffect(() => {
    if (incidentDeclineLinemenSuccess) {
      dispatch(loadLinemen.init({ incidentId }));
      setLinemenSelected([]);
    }
  }, [incidentDeclineLinemenSuccess]);

  useEffect(() => {
    if (transferRosterError) {
      setShowActionsForbiddenSnackBar(true);
    }
  }, [transferRosterError]);

  const metaColumns: TableAdvancedMetaColumn[] = [
    {
      index: 3,
      column: {
        field: 'invite',
        headerName: t('eventPage.invite'),
        width: 100,
        renderCell: (params: GridRenderCellParams) => {
          const isSent = (params.row.incidentLinemen || []).some((invite: IncidentLinemen) => invite.send);
          return isSent ? (
            <>
              {t('eventPage.sent')} <Icon name="checkmark" />
            </>
          ) : null;
        },
      },
    },
    {
      index: 4,
      column: {
        field: 'inviteStatus',
        headerName: t('eventPage.inviteStatus'),
        width: 180,
        renderCell: (params: GridRenderCellParams) => {
          return (
            <Box sx={{ display: 'flex', flexWrap: 'wrap' }}>
              {(params.row.incidentLinemen || []).map((invite: IncidentLinemen) => {
                const inviteStatus = invite.participationStatus;
                const labelType = inviteStatus === 1 ? 'green' : inviteStatus === -1 ? 'red' : 'gray';
                return (
                  <Label
                    key={invite.yardId}
                    type={labelType}
                    text={
                      // @ts-ignore
                      // eslint-disable-next-line no-unsafe-optional-chaining
                      config.options.yards[invite.yardId - 1]?.value
                    }
                  />
                );
              })}
            </Box>
          );
        },
      },
    },
    {
      index: 5,
      column: {
        field: 'declined',
        headerName: t('eventPage.declined'),
        width: 180,
        renderCell: (params: GridRenderCellParams) => {
          return (
            <Box sx={{ display: 'flex', flexWrap: 'wrap' }}>
              {(params.row.incidentLinemen || [])
                .filter((invite: IncidentLinemen) => invite.declined)
                .map((invite: IncidentLinemen) => (
                  <P1R key={invite.yardId}>
                    {
                      // @ts-ignore
                      // eslint-disable-next-line no-unsafe-optional-chaining
                      config.options.yards[invite.yardId - 1]?.value
                    }
                  </P1R>
                ))}
            </Box>
          );
        },
      },
    },
  ];

  const metaRows = linemen?.map((user) => ({
    invite:
      user.participationStatus === 1 ? 'accepted' : user.participationStatus === -1 ? 'rejected' : user.participationStatus === 0 ? 'pending' : 'NaN',
  }));

  const metaColumnsSupport: TableAdvancedMetaColumn[] = [
    {
      index: 3,
      column: {
        field: 'location',
        headerName: 'location',
        width: 140,
        renderCell: (params: any) => params.row.city,
      },
    },
  ];

  if (isLoadIncidentError) {
    return (
      <DashboardWrapper breadcrumbs={[['home'], [`${incident.name || ''} ${formatIncidentId(incidentId)}`]]}>
        <SomethingWentWrongState />
      </DashboardWrapper>
    );
  }

  return (
    <DashboardWrapper
      breadcrumbs={[['home'], [`${incident.name || ''} ${formatIncidentId(incidentId)}`]]}
      navigationChildren={
        config.can('add_roster') && (
          <Button
            sx={{ padding: '8px 16px', textTransform: 'none' }}
            onClick={() => setOpenCreateIncidentDialog(true)}
            disabled={!config.can('add_roster', { user, incident }) || myRosters.filter((roster) => roster.status !== RosterStatus.CLOSED).length > 0}
          >
            {t('eventPage.createRoster')}
          </Button>
        )
      }
    >
      <Box
        component="main"
        sx={{
          display: 'flex',
          flexDirection: 'column',
          flexGrow: 1,
          overflowY: 'hidden',
        }}
      >
        <>
          <Box sx={{ margin: '22px 0' }}>
            <IncidentHeader incident={incident} />
          </Box>
          <Grid
            container
            sx={{
              display: 'flex',
              flexDirection: 'column',
              flexGrow: 1,
              overflowY: 'hidden',
            }}
          >
            <Grid
              item
              xs={12}
              sx={{
                display: 'flex',
                flexDirection: 'column',
                flexGrow: 1,
                overflowY: 'hidden',
              }}
            >
              <Box sx={{ borderBottom: 1, borderColor: 'divider', mb: 2 }}>
                <Tabs value={activeEventTab} onChange={handleTabsChange} aria-label="basic tabs example">
                  <Tab
                    label={t('eventPage.resources')}
                    {...a11yProps(0)}
                    sx={{
                      textTransform: 'none',
                      fontSize: '16px',
                      color: themeColors.grayDark,
                      '&.Mui-selected': { color: 'black' },
                    }}
                  />
                  {canHaveOwnRoster && (
                    <Tab
                      label={t('eventPage.myRosters')}
                      {...a11yProps(1)}
                      sx={{
                        textTransform: 'none',
                        fontSize: '16px',
                        color: themeColors.grayDark,
                        '&.Mui-selected': { color: 'black' },
                      }}
                    />
                  )}
                  <Tab
                    label={t('eventPage.allRosters')}
                    {...a11yProps(2)}
                    sx={{
                      textTransform: 'none',
                      fontSize: '16px',
                      color: themeColors.grayDark,
                      '&.Mui-selected': { color: 'black' },
                    }}
                  />
                </Tabs>
              </Box>
              {isIncidentLoading ? (
                <Loader />
              ) : (
                <>
                  <TabPanel value={activeEventTab} index={0}>
                    <Box
                      gap={3}
                      sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        flexGrow: 1,
                        overflowY: 'hidden',
                      }}
                    >
                      <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                        <Box sx={{ minWidth: 340 }}>
                          <Button
                            buttonType="group"
                            items={[
                              {
                                title: t('eventPage.linemen'),
                                onClick: () => handleResourcesTableChange('linemen'),
                                active: activeResourcesSubtab === 'linemen',
                              },
                              {
                                title: 'Support',
                                onClick: () => handleResourcesTableChange('support'),
                                active: activeResourcesSubtab === 'support',
                              },
                              {
                                title: 'Available Fleet',
                                onClick: () => handleResourcesTableChange('vehicles'),
                                active: activeResourcesSubtab === 'vehicles',
                              },
                            ]}
                          />
                        </Box>
                        {(activeResourcesSubtab === 'vehicles' && vehicles.length) ||
                        (activeResourcesSubtab === 'linemen' && linemen.length) ||
                        (activeResourcesSubtab === 'support' && supporters.length) ? (
                          <Toolbar options="mini" />
                        ) : null}
                        {canUseActions && (
                          <Button
                            buttonType="dropdown"
                            loading={rosterAttachingLinemen || rosterAttachingVehicles}
                            disabled={
                              rosterAttachingLinemen ||
                              rosterAttachingVehicles ||
                              !rosters ||
                              rosters.length === 0 ||
                              (activeResourcesSubtab !== 'linemen' && !rostersList.length) ||
                              (activeResourcesSubtab === 'linemen' && linemenSelected.length === 0) ||
                              (activeResourcesSubtab === 'vehicles' && vehiclesSelected.length === 0) ||
                              (activeResourcesSubtab === 'support' && supportersSelected.length === 0)
                            }
                            popperStyles={{ textAlign: 'left' }}
                            items={activeResourcesSubtab === 'linemen' ? getLinemanActions() : getFleetActions()}
                            // items={activeResourcesSubtab === 'linemen' ? linemanActions : fleetActions}
                          >
                            {t('eventPage.menu')}
                          </Button>
                        )}
                      </Box>
                      <Box
                        sx={{
                          display: 'flex',
                          flexDirection: 'column',
                          flexGrow: 1,
                          overflow: 'hidden',
                        }}
                      >
                        {activeResourcesSubtab === 'linemen' && linemen.length > 0 && (
                          <>
                            <Box sx={{ gap: '24px', display: 'flex', mb: '25px' }}>
                              <div
                                style={{
                                  gap: '8px',
                                  display: 'flex',
                                  alignItems: 'center',
                                }}
                              >
                                <Box sx={{ height: '8px', width: '8px', borderRadius: '50%', backgroundColor: themeColors.green }} />
                                <P1R>{t('eventPage.yes')}</P1R>
                              </div>
                              <div
                                style={{
                                  gap: '8px',
                                  display: 'flex',
                                  alignItems: 'center',
                                }}
                              >
                                <Box sx={{ height: '8px', width: '8px', borderRadius: '50%', backgroundColor: themeColors.red }} />
                                <P1R>{t('eventPage.no')}</P1R>
                              </div>
                              <div
                                style={{
                                  gap: '8px',
                                  display: 'flex',
                                  alignItems: 'center',
                                }}
                              >
                                <Box sx={{ height: '8px', width: '8px', borderRadius: '50%', backgroundColor: themeColors.grayDisabled }} />
                                <P1R>{t('eventPage.pending')}</P1R>
                              </div>
                            </Box>
                            <UsersTable
                              users={linemen}
                              metaColumns={metaColumns}
                              isFavoritesEditable={false}
                              metaRows={metaRows}
                              toolbarProps={{ options: 'mini' }}
                              columnVisibilityModel={{
                                ...usersColumnVisibilityModel,
                                firstName: true,
                                lastName: true,
                                favorite: true,
                                phone: true,
                                email: true,
                                userRoles: true,
                                stateCode: true,
                              }}
                              onRowSelectionModelChange={(selected: any) => {
                                setLinemenSelected(selected);
                              }}
                              rowSelectionModel={linemenSelected}
                              checkboxSelection={canUseActions}
                            />
                          </>
                        )}
                        {((activeResourcesSubtab === 'linemen' && linemen.length === 0) ||
                          (activeResourcesSubtab === 'support' && supporters.length === 0)) && (
                          <Box
                            sx={{
                              margin: 'auto',
                              display: 'flex',
                              alignItems: 'center',
                              justifyContent: 'center',
                              textAlign: 'center',
                              flexDirection: 'column',
                            }}
                          >
                            <Box>
                              <img src={emptyLineman} alt="Lineman picture" />
                            </Box>
                            <H1 sx={{ mb: 3 }}>{t('eventPage.noAvailablePeople')}</H1>
                            <P1R>
                              {t('eventPage.allPeopleAreBusy')}
                              <br />
                              {t('eventPage.tryAgainLater')}
                            </P1R>
                          </Box>
                        )}

                        {activeResourcesSubtab === 'support' && supporters.length > 0 && (
                          <UsersTable
                            users={supporters}
                            metaColumns={metaColumnsSupport}
                            toolbarProps={{ options: 'mini' }}
                            isFavoritesEditable={false}
                            columnVisibilityModel={{
                              ...usersColumnVisibilityModel,
                              firstName: true,
                              lastName: true,
                              favorite: true,
                              phone: true,
                              email: true,
                              userRoles: true,
                            }}
                            onRowSelectionModelChange={(selected: any) => {
                              setSupportersSelected(selected);
                            }}
                            rowSelectionModel={supportersSelected}
                            checkboxSelection={canUseActions}
                          />
                        )}

                        {activeResourcesSubtab === 'vehicles' && vehicles.length > 0 && (
                          <FleetTable
                            fleet={vehicles}
                            user={user}
                            toolbarProps={{ options: 'mini' }}
                            columnVisibilityModel={{
                              ...fleetColumnVisibilityModel,
                              companyTag: true,
                              type: true,
                              yardFK: true,
                            }}
                            onRowSelectionModelChange={(selected: any) => {
                              setVehiclesSelected(selected);
                            }}
                            rowSelectionModel={vehiclesSelected}
                            checkboxSelection={canUseActions}
                          />
                        )}
                        {activeResourcesSubtab === 'vehicles' && vehicles.length === 0 && (
                          <Box
                            sx={{
                              margin: 'auto',
                              display: 'flex',
                              alignItems: 'center',
                              justifyContent: 'center',
                              textAlign: 'center',
                              flexDirection: 'column',
                            }}
                          >
                            <Box>
                              <img src={fleetPic} alt="Lineman picture" />
                            </Box>
                            <H1 sx={{ mb: 3 }}>{t('eventPage.noAvailableFleet')}</H1>
                            <P1R>
                              {t('eventPage.fleetIsBusy')}
                              <br />
                              {t('eventPage.maintanance')}
                            </P1R>
                          </Box>
                        )}
                      </Box>
                    </Box>
                  </TabPanel>
                  {canHaveOwnRoster && (
                    <TabPanel value={activeEventTab} index={1}>
                      {isRostersLoadingError ? (
                        <SomethingWentWrongState />
                      ) : (
                        <>
                          <Grid container spacing={3} sx={{ display: 'flex', alignItems: 'center' }}>
                            <Grid item xs={6}>
                              <H2
                                sx={{
                                  gap: '16px',
                                  display: 'flex',
                                  alignItems: 'center',
                                }}
                              >
                                {t('eventPage.rosters')}
                                <P1R
                                  sx={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    padding: '5px 15px',
                                    height: '24px',
                                    borderRadius: '45px',
                                    backgroundColor: themeColors.grayMedium,
                                  }}
                                >
                                  {myRosters.length}
                                </P1R>
                              </H2>
                            </Grid>
                          </Grid>
                          {myRosters && myRosters.length > 0 ? (
                            <Grid container spacing={3} sx={{ mt: 0 }}>
                              {myRosters.map((roster) => (
                                <Grid key={roster.id} item xs={3}>
                                  <CardRoster
                                    roster={roster}
                                    onRosterDelete={() =>
                                      setRosterToDelete({
                                        id: roster.id,
                                        name: roster.name,
                                      })
                                    }
                                    user={user}
                                  />
                                </Grid>
                              ))}
                            </Grid>
                          ) : (
                            <Box
                              sx={{
                                margin: 'auto',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                textAlign: 'center',
                                flexDirection: 'column',
                              }}
                            >
                              <Box>
                                <img src={linemanPic} alt="Lineman picture" />
                              </Box>
                              <H1 sx={{ mb: 3 }}>{t('eventPage.noRostersCreated')}</H1>
                              <P1R>
                                {t('eventPage.correspondingButton')}
                                <br />
                                {t('eventPage.firstRoster')}
                              </P1R>
                            </Box>
                          )}
                        </>
                      )}
                    </TabPanel>
                  )}
                  {/* <TabPanel value={tabValue} index={canHaveOwnRoster ? 2 : 1}> */}
                  <TabPanel value={activeEventTab} index={canHaveOwnRoster ? 2 : 1}>
                    {isRostersLoadingError ? <SomethingWentWrongState /> : <AllRostersTab rosters={rosters} user={user} />}
                  </TabPanel>
                </>
              )}
            </Grid>
          </Grid>
        </>
      </Box>
      {selectedRoster ? (
        <RosterSnackbar open={showSnackbar} onClick={handleGoToRoster} onClose={() => setShowSnackbar(false)} rosterId={selectedRoster} />
      ) : null}
      {openCreateIncidentDialog && <Dialog template="create-roster" onClose={() => setOpenCreateIncidentDialog(false)} incidentId={incidentId} />}
      {rosterToDelete && (
        <DeletePopup id={rosterToDelete.id} name={rosterToDelete.name} onClose={() => setRosterToDelete(null)} onDelete={handleDeleteRoster} />
      )}
      {openAddToRosterDialog && <AddToRosterDialog onClose={() => setOpenAddToRosterDialog(false)} onConfirm={handleAddToRosterConfirm} />}
      {openSendInviteDialog && (
        <SendInvitePopup
          incidentYardId={incident.yardFK}
          onClose={() => setOpenSendInviteDialog(false)}
          onConfirm={(yard: number[]) => handleSendOfferConfirm(yard)}
        />
      )}
      <Notification open={invitationSent} onClose={() => setInvitationSent(false)}>
        <P2 sx={{ color: themeColors.white, textTransform: 'none' }}>{t('eventPage.inviteSent')}</P2>
      </Notification>
      {notifyError && <ActionForbiddenPopup errorMessage={user.errorMessage} onClose={() => dispatch(notifyUsers.reset())} />}
      {attachResourceError && <ActionForbiddenPopup errorMessage={attachResourceError} onClose={() => setAttachResourceError('')} />}
      {showDeclineLinemenDialog && (
        <DeclineLinemenPopup
          invitedYards={invitedYardsIds}
          onClose={() => setsShowDeclineLinemenDialog(false)}
          onConfirm={(yardIds: number[]) => handleDeclineLinemen(yardIds)}
        />
      )}
      {rosterAttachingLinemenErrors && (
        <LinemanNotAvailableDialog
          errorMessage={rosterAttachingLinemenErrors.message}
          onConfirm={resetAttachingLinemen}
          onClose={resetAttachingLinemen}
        />
      )}
      <Notification open={isRosterSplited} onClose={() => setIsRosterSplited(false)} anchorOrigin={{ vertical: 'top', horizontal: 'right' }}>
        <P2 sx={{ color: themeColors.white, textTransform: 'none' }}>Roster {state?.splitRoster} was successfully split.</P2>
      </Notification>
      <ActionsAreForbiddenSnackbar open={showActionsForbiddenSnackBar} onClose={() => setShowActionsForbiddenSnackBar(false)} />
    </DashboardWrapper>
  );
}

export default Incident;
