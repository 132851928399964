import styled from '@emotion/styled';
import { themeColors } from 'src/theme';
import { ClearStatus as ClearStatusEnum } from 'src/shared/enums/clearStatus.enum';
import { P3 } from '../Typography';

const StyledStatus = styled(P3)({
  padding: '0 10px',
  display: 'flex',
  alignItems: 'center',
  textTransform: 'lowercase',
  borderRadius: '4px',
});

export const clearStatusNameByCode = {
  [ClearStatusEnum.DECLINED]: 'failed',
  [ClearStatusEnum.NOT_STARTED]: 'not started',
  [ClearStatusEnum.APPROVED]: 'success',
  [ClearStatusEnum.INCOMPLETE]: 'incomplete',
};

const colorByStatusCode = {
  [ClearStatusEnum.DECLINED]: themeColors.redLight,
  [ClearStatusEnum.NOT_STARTED]: themeColors.lightYellow,
  [ClearStatusEnum.APPROVED]: themeColors.greenLight,
  [ClearStatusEnum.INCOMPLETE]: themeColors.blueLight,
};

type ClearStatusProps = {
  clearStatus: ClearStatusEnum;
};

const ClearStatus = ({ clearStatus }: ClearStatusProps) => {
  return <StyledStatus sx={{ backgroundColor: colorByStatusCode[clearStatus] }}>{clearStatusNameByCode[clearStatus]}</StyledStatus>;
};

export default ClearStatus;
