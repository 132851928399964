import { styled } from '@mui/material/styles';
import { P3 } from 'src/components';
import { UnionInfoStatus } from 'src/shared/enums/unionInfoStatus.enum';
import { themeColors } from 'src/theme';

const StyledStatus = styled(P3)({
  padding: '0 10px',
  display: 'flex',
  alignItems: 'center',
  textTransform: 'lowercase',
  borderRadius: '4px',
});

export const verificationStatusNameByCode = {
  [UnionInfoStatus.PENDING]: 'pending',
  [UnionInfoStatus.VERIFIED]: 'verified',
  [UnionInfoStatus.REJECTED]: 'rejected',
};

const colorByStatusCode = {
  [UnionInfoStatus.PENDING]: themeColors.lightYellow,
  [UnionInfoStatus.VERIFIED]: themeColors.greenLight,
  [UnionInfoStatus.REJECTED]: themeColors.redLight,
};

type VerificationProps = {
  verificationStatus: UnionInfoStatus;
};

const VerificationStatus = ({ verificationStatus: status }: VerificationProps) => {
  return <StyledStatus sx={{ backgroundColor: colorByStatusCode[status] }}>{verificationStatusNameByCode[status]}</StyledStatus>;
};

export default VerificationStatus;
