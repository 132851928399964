export const isObject = (obj: unknown): boolean => !!obj && typeof obj === 'object';

export const getUrl = (url: string, params: Record<string, any>): string => {
  let result = url;
  // eslint-disable-next-line guard-for-in
  for (const key in params) {
    const placeholder = `{${key}}`;
    const value = `${params[key]}`;
    result = result.replace(placeholder, value);
  }
  return result;
};

export const setParams = (route: string, params: { [key: string]: string }): string =>
  Object.keys(params).reduce((newRoute, paramKey) => newRoute.replace(`:${paramKey}`, params[paramKey]), route);

export const toCamelCase = (fieldName: string): string =>
  fieldName
    .toLowerCase()
    .replace(/_/g, ' ')
    .split(' ')
    .map((word, index) => {
      if (index === 0) {
        return word.toLowerCase();
      }
      return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
    })
    .join('');

export const formatIncidentId = (id: number | undefined) => id?.toString().padStart(6, '0');

export function enumToTranslatedOptions(
  e: { [name: string]: string },
  t: (a: string) => string,
  enumName: string,
): { key: number | string; value: string }[] {
  return Object.keys(e).map((key) => {
    return { key, value: t(`${enumName}.${key}`) };
  });
}

export function translateArray(arr: string[], t: (a: string) => string, baseKey: string): string[] {
  return arr.map((item) => {
    return t(`${baseKey}.${item}`);
  });
}
